import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

import { Button, Box, Container, Typography, Grid, Tooltip } from "@mui/material";
import { spacing } from "@mui/system";
import { ArrowForward as ArrowForwardIcon } from "@mui/icons-material";

const Wrapper = styled.div`
  ${spacing};
  text-align: center;
`;

const Content = styled.div`
  padding: ${(props) => props.theme.spacing(6)} 0;
  line-height: 150%;
`;

const Title = styled(Typography)`
  opacity: 0.9;
  line-height: 1.4;
  font-size: 1.75rem;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};

  ${(props) => props.theme.breakpoints.up("sm")} {
    font-size: 2rem;
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 2.5rem;
  }

  span {
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const TypographyOverline = styled(Typography)`
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.primary.main};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const BrandIcon = styled.img`
  vertical-align: middle;
  height: auto;
`;

const Brand = styled.div`
  background: white;
  display: inline-block;
  padding: 12px 16px;
  border-radius: 10px;
  margin: 10px;
`;

const ArrowForward = styled(ArrowForwardIcon)`
  margin-left: ${(props) => props.theme.spacing(2)};
`;

function Integrations() {
  return (

    <Wrapper>
      <Container>
        <Grid container alignItems="left" justifyContent="center" spacing={4}>
          <Grid item xs={7} sm={8} md={9} lg={12}>
            <Content>
              {/* <Version variant="body2">v3.2.1</Version> */}
              <Title variant="h1" gutterBottom>
              </Title>
              <Grid container justifyContent="center" spacing={4}>
              <Box>
                <>
                  {/* Begin Mailchimp Signup Form */}
                  <link
                    href="//cdn-images.mailchimp.com/embedcode/classic-071822.css"
                    rel="stylesheet"
                    type="text/css"
                  />
                  

                  <style
                    type="text/css"
                    dangerouslySetInnerHTML={{
                      __html:
                        "\n\t#mc_embed_signup{clear:left;  max-width:600px;}#mc_embed_signup .helper_text{ background-color: rgba(255,255,255,0.0);}\n\t/* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.\n\t   We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */\n"
                    }}
                  />
                  <div id="mc_embed_signup">
                    <form
                      action="https://ovisproject.us7.list-manage.com/subscribe/post?u=28203c0b1ccee3b24805bc664&id=5aa1bcef6f&f_id=005ad2e4f0"
                      method="post"
                      id="mc-embedded-subscribe-form"
                      name="mc-embedded-subscribe-form"
                      className="validate"
                      target="_blank"
                    >
                      <div id="mc_embed_signup_scroll">
                        <h2>CONTACT US</h2>
                        <div className="indicates-required">
                          <span className="asterisk">*</span> indicates required
                        </div>
                        <div className="mc-field-group">
                          <label htmlFor="mce-EMAIL">
                            Email Address <span className="asterisk">*</span>
                          </label>
                          <input
                            type="email"
                            defaultValue=""
                            name="EMAIL"
                            className="required email"
                            id="mce-EMAIL"
                          />
                          <span id="mce-EMAIL-HELPERTEXT" className="helper_text" />
                        </div>
                        <div className="mc-field-group">
                          <label htmlFor="mce-FNAME">First Name </label>
                          <input
                            type="text"
                            defaultValue=""
                            name="FNAME"
                            className=""
                            id="mce-FNAME"
                          />
                          <span id="mce-FNAME-HELPERTEXT" className="helper_text" />
                        </div>
                        <div className="mc-field-group">
                          <label htmlFor="mce-LNAME">Last Name </label>
                          <input
                            type="text"
                            defaultValue=""
                            name="LNAME"
                            className=""
                            id="mce-LNAME"
                          />
                          <span id="mce-LNAME-HELPERTEXT" className="helper_text" />
                        </div>
                        <div className="mc-field-group">
                          <label htmlFor="mce-USERNAME">Username </label>
                          <input
                            type="text"
                            defaultValue=""
                            name="USERNAME"
                            className=""
                            id="mce-USERNAME"
                          />
                          <span id="mce-USERNAME-HELPERTEXT" className="helper_text" />
                        </div>
                        <div className="mc-field-group">
                          <label htmlFor="mce-COMPANY">Company </label>
                          <input
                            type="text"
                            defaultValue=""
                            name="COMPANY"
                            className=""
                            id="mce-COMPANY"
                          />
                          <span id="mce-COMPANY-HELPERTEXT" className="helper_text" />
                        </div>
                        <div id="mce-responses" className="clear foot">
                          <div
                            className="response"
                            id="mce-error-response"
                            style={{ display: "none" }}
                          />
                          <div
                            className="response"
                            id="mce-success-response"
                            style={{ display: "none" }}
                          />
                        </div>{" "}
                        {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups*/}
                        <div
                          style={{ position: "absolute", left: "-5000px" }}
                          aria-hidden="true"
                        >
                          <input
                            type="text"
                            name="b_28203c0b1ccee3b24805bc664_5aa1bcef6f"
                            tabIndex={-1}
                            defaultValue=""
                          />
                        </div>
                        <div className="optionalParent">
                          <div className="clear foot">
                            <input
                              type="submit"
                              defaultValue="Subscribe"
                              name="subscribe"
                              id="mc-embedded-subscribe"
                              className="button"
                            />
                            <p className="brandingLogo">
                              <a
                                href="http://eepurl.com/hRF5_r"
                                title="Mailchimp - email marketing made easy and fun"
                              >
                                <img src="https://eep.io/mc-cdn-images/template_images/branding_logo_text_dark_dtp.svg" />
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  {/*End mc_embed_signup*/}          
                </>
            </Box>
          </Grid>
            </Content>
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
}

export default Integrations;
