import React, { useState, useEffect } from 'react';
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";

import { Box, Chip, Container, Grid, Typography } from "@mui/material";
import { spacing } from "@mui/system";

import { THEMES } from "../../../constants";
import useTheme from "../../../hooks/useTheme";
import { Markup } from 'interweave';
import { ComponentProps } from '../../../types/page'

const Wrapper = styled.div`
  ${spacing};
  background: ${(props) => props.theme.palette.background.paper};
  text-align: center;
`;

const DemoContent = styled.div(spacing);

const DemoLink = styled.div`
  cursor: pointer;
`;

const DemoImage = styled.img`
  max-width: 100%;
  height: auto;
  display: block;
  box-shadow: 0 4px 12px 0 rgba(18, 38, 63, 0.125);
  transition: 0.15s ease-in-out;
  border-radius: 4px;

  &:hover {
    transform: scale(1.0325);
  }
`;

const DemoChip = styled(Chip)`
  background-color: ${(props) => props.theme.palette.secondary.main};
  border-radius: 5px;
  color: ${(props) => props.theme.palette.common.white};
  font-size: 55%;
  height: 18px;
  margin-top: -16px;
  padding: 3px 0;

  span {
    padding-left: ${(props) => props.theme.spacing(1.375)};
    padding-right: ${(props) => props.theme.spacing(1.375)};
  }
`;

const TypographyOverline = styled(Typography)`
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.primary.main};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

type VariantProps = {
  theme: string;
  title: string;
  img: string;
  description: string;
  isNew?: boolean;
};

const Variant: React.FC<VariantProps> = ({
  theme,
  title,
  img,
  description,
  isNew = false,
}) => {

  console.log("IMGGE", img);
  const navigate = useNavigate();
  const { setTheme } = useTheme();

  const toggleDemo = (theme: string) => {
    setTheme(theme);
    navigate("/dashboard/default");
  };
  
  return (
    <Grid item xs={12} sm={6} md={4} lg={4}>
      <DemoContent px={2}>
        <DemoLink onClick={() => toggleDemo(theme)}>
          <DemoImage
            alt={`${title}`}
            src={`${img}`}
          />
        </DemoLink>
        <Box mb={3} />
        <Typography variant="h6">
          {title} {isNew && <DemoChip label="New" />}
        </Typography>
        <Typography>
          {description}
        </Typography>
      </DemoContent>
    </Grid>
  );
};

type HTMLProps = {
  html: string;
};
const Feature: React.FC<HTMLProps> = ({ html }) => {
  console.log(html)
  return (
    <Grid item xs={12} sm={6} md={4} lg={12}>
      <Box mb={10}>
        <Typography variant="subtitle1" gutterBottom my={4}>
          <Markup content={html}/>
        </Typography>
      </Box>
    </Grid>
  );
};


const ContentPanel: React.FC<ComponentProps> = ({props}) => {
// function Banner(pageData:IndivPageProps) { 
  // let arr = pageData?.page?.components?.sections_to_include?.split(',') || ["directions"];
  // let pageTitle = pageData.page?.details.page_title;
  // console.log("page data", pageData);  
  return (
    <Wrapper pt={16} pb={20} id="demos">
      <Container>
        {/* <TypographyOverline variant="body2" gutterBottom>
          Demos
        </TypographyOverline> */}
        <Typography variant="h2" component="h3" gutterBottom>
          {props?.content?.h2}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" gutterBottom>
          {props?.content?.body}
        </Typography>
        <Box mb={8} />
        <Grid container spacing={6}>
        { 
            props?.contents?.map((content: any, i: number) => {
              return (
                // <div>HI</div>
                // <Variant key={faq.question} question={faq.question} answer={faq.answer}/>
                <Variant key={content.title} theme={THEMES.DEFAULT} title={content.title} img={content.src} description={content.description}/>
              )
            }
          )
        }

        {/* <Grid container spacing={10}>
           <Variant
            theme={THEMES.DEFAULT}
            title="Default variant"
            img="image1"
          />
          <Variant theme={THEMES.BLUE} title="Blue variant" img="image2" />
          <Variant theme={THEMES.DARK} title="Dark variant" img="image3" isNew />
          {/* <Variant theme={THEMES.GREEN} title="Green variant" img="green" />
          <Variant theme={THEMES.INDIGO} title="Indigo variant" img="indigo" />
          <Variant theme={THEMES.LIGHT} title="Light variant" img="light" /> */}
        </Grid>
      </Container>
    </Wrapper>
  );
}

export default ContentPanel;
