// import axiosInstance from "../services/auth.service";
// import mock from "./adapter";
import axiosInstancePublic from "../../utils/axios";
import aI from "../../utils/axios";
import axiosInstance from "./auth.service";

import moment from "moment";

function dateToEpoch(date: any) {
      var epoch = moment(date).unix();
      return epoch;
}

// const getTest = () => {
//   return axiosInstance.get(`/ovis/test`).then((res) => res.data);
// };

const getProjects = () => {  
  // return axiosInstance.get(`/ovis/app/projects/manage?fields=projectId,projectTitle,projectName,web_view_link,details,body,id,projectStatus`).then((res) => res.data);
  return axiosInstancePublic.get(`https://ovisdashboard.s3.amazonaws.com/static/json/master_projects.json`).then((res) => res.data);
};

// const getProjectsPublic = () => {
//   return axiosInstancePublic.get(`/ovis/testcors?fields=projectId,projectTitle,projectName,web_view_link,details,body,id,projectStatus`).then((res) => res.data);
// };

  // return axiosInstance.get(`/ovis/web/content/page?title=`+title).then((res) => res.data);

const getPages = () => {
  return axiosInstancePublic.get(`https://ovisdashboard.s3.amazonaws.com/static/json/master_content.json`).then((res) => res.data);
};

const getInventoryImages = () => {
  return axiosInstance.get(`/ovis/app/uploads/inventory?bucket_name=ovispublish&account=luke.fleshman@gmail.com&box_key=box_alligator`).then((res) => res.data);
}

const getTasks = () => {
  return axiosInstance.get(`/ovis/app/tasks/manage`).then((res) => res.data);
};
// https://wn5uv63nwd.execute-api.us-east-1.amazonaws.com/v1/ovis/web/content/content

// const getExploreIamPolicies = (startDate: number, endDate: number) => {
//   return axiosInstance.get(`/explore/aws_iam_policies`).then((res) => res.data.body);
// };

// const getSecurityExploitedVulnerabilties = () => {
//   return axiosInstance.get(`/api/v1/cmdb/explore/conmon_cisa_exploited_vulnerabilties`).then((res) => res.data.body);
// };

// const getSecurityRunningEcsTasksWithFindings = () => {
//   return axiosInstance.get(`/api/v1/security/aws_ecs_running_tasks_with_findings`).then((res) => res.data.body);
// };

// const getExploreEcrScanResults = () => {
//   return axiosInstance.get(`/explore/aws_ecr_images`).then((res) => res.data.body);
// };

// const getExploreEcrScanResultDetails = (startDate: number, endDate: number) => {
//   return axiosInstance.get(`/explore/aws_ecr_image_scan_finding_details`).then((res) => res.data.body);
// };

// const getExploreEcrImages = (startDate: number, endDate: number) => {
//   return axiosInstance.get(`/explore/aws_ecr_images`).then((res) => res.data.body);
// };



// const getEc2Summary = (startDate: number, endDate: number) => {
//     // let start: number = this.dateToEpoch(startDate.toString());
//     // let end: number = this.dateToEpoch(endDate.toString());
//     let start: number = 0;
//     let end: number = 999999999999;
//     if (start === end) {
//       end = end + 86400;
//       return axiosInstance
//         .get(`/cmdb/resources?_startdate=${start}&_enddate=${end}`)
//         .then((res) => res.data.body);
//     } else {
//       return axiosInstance
//         .get(`/cmdb/resources?_startdate=${start}&_enddate=${end}`)
//         .then((res) => res.data.body);
//     }
//   };

const apiService = {
    // getTest,
    getProjects,
    // getProjectsPublic,
    getPages,
    getTasks,
    getInventoryImages,
    // getExploreIamPolicies,
    // getSecurityExploitedVulnerabilties,
    // getSecurityRunningEcsTasksWithFindings,
    // getExplorerEcrScanResults,
    // getExploreEcrScanResultDetails,
    // getExploreEcrImages
    // getEc2Summary
};

export default apiService;



// import axios from "axios";
// import moment from "moment";

// import useAuth from "../../hooks/useAuth";

// class CmdbApi {
//   private endpoint;
//   constructor() {
//     this.endpoint =
//       process.env.FAST_API_URL ||
//       "https://govtools-seneca-internal-alb-2101457893.us-gov-west-1.elb.amazonaws.com";
//   }

//   dateToEpoch(date: any) {
//     var epoch = moment(date).unix();
//     return epoch;
//   }

//   async getCmdbOverview() {
//     return axios
//       .get(
//         `${this.endpoint}/api/v1/cmdb/dashboard/summary`,

//         {
//           headers: {
//             Authorization:
//               "Bearer eyJraWQiOiJ5MnhWZ2V6NkowNFFrbGRjbjdYV1YwNGxPbkc0Zm1ETUlsUEs2MWVEdjhNPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI5NWM3MDA2YS03YWVlLTQzOTItYWM1ZS04ODAyMWNiOTUxYTEiLCJjb2duaXRvOmdyb3VwcyI6WyJzZW5lY2EtYWRtaW5zIiwidXMtZ292LXdlc3QtMV8yNktSdG9sQ2NfU2VuZWNhT2F1dGgiXSwidG9rZW5fdXNlIjoiYWNjZXNzIiwic2NvcGUiOiJhd3MuY29nbml0by5zaWduaW4udXNlci5hZG1pbiBvcGVuaWQgcHJvZmlsZSBlbWFpbCIsImF1dGhfdGltZSI6MTY0MjE5MDAzMiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWdvdi13ZXN0LTEuYW1hem9uYXdzLmNvbVwvdXMtZ292LXdlc3QtMV8yNktSdG9sQ2MiLCJleHAiOjE2NDIxOTM2MzIsImlhdCI6MTY0MjE5MDAzMiwidmVyc2lvbiI6MiwianRpIjoiYTE2YjdlYjgtNGVlNi00NjhjLWJiNjItMGU2ZTJjYTZkMWJkIiwiY2xpZW50X2lkIjoiMm5lNWVsMmFzdDRsZmJmcmcxc25uNG9sciIsInVzZXJuYW1lIjoiU2VuZWNhT2F1dGhfcnNIWWlSYkhrU3dOUFdwMzNMLTdnSGdxVmVmN21od3BIWHNjZVdZQm5UZyJ9.K5jeLm4DwxCx6j2jKkwucxTgXcKYUgjEClz1CD1nSonX7DuTcJrr0d4Wne-gRp9P0L17FwtIpVBLkLiwuRaRBGqR0q9pCwunrnKylTBJqDFATtAGJRZvl6kzFa5lnKH7kEjDSjkTziMyvI537mSzh1rnuIl4R1hV8wiT5FdP-dLlKzMXDZg06fVOrjvWRClloFckDdWfeNM601fJ6mkyZ0msFOuWy_tvnmY1g8Hw1JPdOl9d24sy7Nju70jqF8EjpTLSPx9USAg0CrBi0ETEoFUKNQTft2JhWSUiRZIAwhs9-92gq0VDq3bRyYkVaYlzZZfw1IPzKHRDfoywZJcejA",
//           },
//         }
//       )
//       .then((res) => res.data.body);
//   }

//   getEc2Summary(startDate: number, endDate: number) {
//     // let start: number = this.dateToEpoch(startDate.toString());
//     // let end: number = this.dateToEpoch(endDate.toString());
//     let start: number = 0;
//     let end: number = 999999999999;
//     if (start === end) {
//       end = end + 86400;
//       return axios
//         .get(
//           `${this.endpoint}/api/v1/cmdb/resources?_startdate=${start}&_enddate=${end}`,
//           {
//             headers: {
//               Authorization:
//                 "Bearer eyJraWQiOiJ5MnhWZ2V6NkowNFFrbGRjbjdYV1YwNGxPbkc0Zm1ETUlsUEs2MWVEdjhNPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI5NWM3MDA2YS03YWVlLTQzOTItYWM1ZS04ODAyMWNiOTUxYTEiLCJjb2duaXRvOmdyb3VwcyI6WyJzZW5lY2EtYWRtaW5zIiwidXMtZ292LXdlc3QtMV8yNktSdG9sQ2NfU2VuZWNhT2F1dGgiXSwidG9rZW5fdXNlIjoiYWNjZXNzIiwic2NvcGUiOiJhd3MuY29nbml0by5zaWduaW4udXNlci5hZG1pbiBvcGVuaWQgcHJvZmlsZSBlbWFpbCIsImF1dGhfdGltZSI6MTY0MjE5MDAzMiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWdvdi13ZXN0LTEuYW1hem9uYXdzLmNvbVwvdXMtZ292LXdlc3QtMV8yNktSdG9sQ2MiLCJleHAiOjE2NDIxOTM2MzIsImlhdCI6MTY0MjE5MDAzMiwidmVyc2lvbiI6MiwianRpIjoiYTE2YjdlYjgtNGVlNi00NjhjLWJiNjItMGU2ZTJjYTZkMWJkIiwiY2xpZW50X2lkIjoiMm5lNWVsMmFzdDRsZmJmcmcxc25uNG9sciIsInVzZXJuYW1lIjoiU2VuZWNhT2F1dGhfcnNIWWlSYkhrU3dOUFdwMzNMLTdnSGdxVmVmN21od3BIWHNjZVdZQm5UZyJ9.K5jeLm4DwxCx6j2jKkwucxTgXcKYUgjEClz1CD1nSonX7DuTcJrr0d4Wne-gRp9P0L17FwtIpVBLkLiwuRaRBGqR0q9pCwunrnKylTBJqDFATtAGJRZvl6kzFa5lnKH7kEjDSjkTziMyvI537mSzh1rnuIl4R1hV8wiT5FdP-dLlKzMXDZg06fVOrjvWRClloFckDdWfeNM601fJ6mkyZ0msFOuWy_tvnmY1g8Hw1JPdOl9d24sy7Nju70jqF8EjpTLSPx9USAg0CrBi0ETEoFUKNQTft2JhWSUiRZIAwhs9-92gq0VDq3bRyYkVaYlzZZfw1IPzKHRDfoywZJcejA",
//             },
//           }
//         )
//         .then((res) => res.data.body);
//     } else {
//       return axios
//         .get(
//           `${this.endpoint}/api/v1/cmdb/resources?_startdate=${start}&_enddate=${end}`,
//           {
//             headers: {
//               Authorization:
//                 "Bearer eyJraWQiOiJ5MnhWZ2V6NkowNFFrbGRjbjdYV1YwNGxPbkc0Zm1ETUlsUEs2MWVEdjhNPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI5NWM3MDA2YS03YWVlLTQzOTItYWM1ZS04ODAyMWNiOTUxYTEiLCJjb2duaXRvOmdyb3VwcyI6WyJzZW5lY2EtYWRtaW5zIiwidXMtZ292LXdlc3QtMV8yNktSdG9sQ2NfU2VuZWNhT2F1dGgiXSwidG9rZW5fdXNlIjoiYWNjZXNzIiwic2NvcGUiOiJhd3MuY29nbml0by5zaWduaW4udXNlci5hZG1pbiBvcGVuaWQgcHJvZmlsZSBlbWFpbCIsImF1dGhfdGltZSI6MTY0MjE5MDAzMiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWdvdi13ZXN0LTEuYW1hem9uYXdzLmNvbVwvdXMtZ292LXdlc3QtMV8yNktSdG9sQ2MiLCJleHAiOjE2NDIxOTM2MzIsImlhdCI6MTY0MjE5MDAzMiwidmVyc2lvbiI6MiwianRpIjoiYTE2YjdlYjgtNGVlNi00NjhjLWJiNjItMGU2ZTJjYTZkMWJkIiwiY2xpZW50X2lkIjoiMm5lNWVsMmFzdDRsZmJmcmcxc25uNG9sciIsInVzZXJuYW1lIjoiU2VuZWNhT2F1dGhfcnNIWWlSYkhrU3dOUFdwMzNMLTdnSGdxVmVmN21od3BIWHNjZVdZQm5UZyJ9.K5jeLm4DwxCx6j2jKkwucxTgXcKYUgjEClz1CD1nSonX7DuTcJrr0d4Wne-gRp9P0L17FwtIpVBLkLiwuRaRBGqR0q9pCwunrnKylTBJqDFATtAGJRZvl6kzFa5lnKH7kEjDSjkTziMyvI537mSzh1rnuIl4R1hV8wiT5FdP-dLlKzMXDZg06fVOrjvWRClloFckDdWfeNM601fJ6mkyZ0msFOuWy_tvnmY1g8Hw1JPdOl9d24sy7Nju70jqF8EjpTLSPx9USAg0CrBi0ETEoFUKNQTft2JhWSUiRZIAwhs9-92gq0VDq3bRyYkVaYlzZZfw1IPzKHRDfoywZJcejA",
//             },
//           }
//         )
//         .then((res) => res.data.body);
//     }
//   }
// }

// export default CmdbApi;
