import React from "react";
import styled from "styled-components/macro";

import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StarIcon from '@mui/icons-material/Star';
import { Box } from '@mui/material';
import { ReactComponent as Logo } from "../../../vendor/op_logo_large.svg";

import {
  Button as MuiButton,
  Container,
  Grid,
  Paper,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";


interface ButtonProps extends SpacingProps {
  component?: React.ElementType;
  to?: string;
  target?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

const BrandIcon = styled(Logo)`
  margin-right: ${(props) => props.theme.spacing(2)};
  margin-top: -2px;
  color: ${(props) => props.theme.sidebar.header.brand.color};
  fill: ${(props) => props.theme.sidebar.header.brand.color};
  width: 128px;
  // height: 64px;

  vertical-align: middle;
  display: inline;
`;


const Spacer = styled.div(spacing);

const Typography = styled(MuiTypography)(spacing);

const Wrapper = styled.div`
  ${spacing};
  text-align: center;
  position: relative;
  background: #181d2d;
  color: ${(props) => props.theme.palette.common.white};
`;

const Subtitle = styled(Typography)`
  font-size: ${(props) => props.theme.typography.h6.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  font-family: ${(props) => props.theme.typography.fontFamily};
  opacity: 0.75;
`;

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="white" align="center" {...props}>
      {'Copyright © '}
      <Link color="white" href="https://ovisproject.org">
        Ovis Project
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export interface FooterProps {
  text: string;
  url?: string;
}

const footers = [
  {
    title: 'Ovis Project',
    image: '',
    description: [
      { 
        text: "",
        url: "/"
      }
    ],
  },
  {
    title: 'Company',
    description: [
      { 
        text: "History",
        url: "/history"
      },
      { 
        text: "Contact us",
        url: "/contact_us"
      },
      { 
        text: "Projects",
        url: "/project_search"
      },
    ],
  },
  {
    title: 'Contribution',
    description: [
      { 
        text: "Donate",
        url: "/donate"
      },
      { 
        text: "Volunteer",
        url: "/contribution"
      },
      { 
        text: "Host",
        url: "/contribution"
      }
    ],
  },
  {
    title: 'Legal',
    description: [
      { 
        text: "Privacy policy",
        url: "/privacy"

      },
      { 
        text: "Terms of use",
        url: "/terms"
      }
    ],
  },
];

function JoinUs() {
  return (
    <Wrapper mt={10} pt={16} pb={16}>
      <Container>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12} md={12} lg={12} xl={12}>
            {/* <Typography variant="h2" gutterBottom>
              Start building with Material App today
            </Typography>
            <Subtitle variant="h5" gutterBottom>
              Stop wasting time building your application from scratch. Material
              App is fast, extendable and fully customizable.
            </Subtitle>
            <Spacer mb={4} />

            <Button
              href="https://material-ui.com/store/items/material-app/"
              variant="contained"
              color="primary"
              size="large"
              target="_blank"
            >
              Get Material App
            </Button> */}
            <Container
              maxWidth="lg"
              component="footer"
              sx={{
                borderTop: (theme) => `1px solid ${theme.palette.divider}`,
                
                mt: 1,
                py: [3, 6],
              }}
            >
              
              <Grid container spacing={6} justifyContent="space-evenly">
                {footers.map((footer) => (
                  <Grid item xs={6} sm={3} key={footer.title}>
                    <Box>
                        <Box
                            sx={{
                              width: 300,
                              height: 34,
                              pl: 18,
                              // backgroundColor: 'primary.dark',
                              // '&:hover': {
                              //   backgroundColor: 'primary.main',
                              //   opacity: [0.9, 0.8, 0.7],
                              // },
                            }}
                          >
                        <Typography variant="subtitle2" display="block" align="left" gutterBottom color="white">
                          {
                            footer.image != undefined
                            ? (<Button href="/"><BrandIcon/></Button>)
                            : footer.title
                          }
                        </Typography>
                      </Box>
                        <List
                        sx={{ width: '100%', maxWidth: 360, pl: 0 }}
                        aria-label={footer.title}
                        >
                        {footer.description.map((item:FooterProps) => (
                          // <ListItem key={item.text} button component={Link} to={item.url} disablePadding>
                          <ListItem key={item.text} button component="a" href={item.url}>
                            <ListItemText 
                                  primaryTypographyProps={{ fontSize: 10, fontWeight: 'medium' }}                           
                                  inset 
                                  primary={item.text}  />
                          </ListItem>                            
  
                        ))}
                        </List>
                      </Box>
                  </Grid>
                ))}
              </Grid>
              <Box sx={{pt: 15}}>
                <Copyright sx={{ mt: 5 }} />
              </Box>
            </Container>
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
}

export default JoinUs;
