import React, { useState, useEffect } from 'react';
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { rgba } from "polished";

import {
  Box,
  Button,
  Container,
  Grid,
  Tooltip,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { Visibility as VisibilityIcon } from "@mui/icons-material";
import { ArrowForward as ArrowForwardIcon } from "@mui/icons-material";
import apiService from "../../../app/services/api";
import { Markup } from 'interweave';
import { Item } from 'semantic-ui-react';
import { ComponentProps } from '../../../types/page'
const Typography = styled(MuiTypography)(spacing);

const Wrapper = styled.div`
  padding-top: 3.5rem;
  position: relative;
  text-align: center;
  overflow: hidden;
`;

const Content = styled.div`
  padding: ${(props) => props.theme.spacing(6)} 0;
  line-height: 150%;
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
  display: block;
  box-shadow: 0 6px 18px 0 rgba(18, 38, 63, 0.075);
  border-radius: 5px;
  transform: perspective(2000px) rotateX(25deg);
  z-index: 0;
  position: relative;
  image-rendering: optimizequality;
  image-rendering: -webkit-optimize-contrast;
  margin-bottom: -100px;
  margin-top: -35px;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-top: -50px;
  }
`;

const ImageWrapper = styled.div`
  &:before {
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.02));
    bottom: 0;
    left: 0;
    position: absolute;
    content: " ";
    z-index: 1;
    display: block;
    width: 100%;
    height: 75px;
    pointer-events: none;
  }
`;

const Title = styled(Typography)`
  opacity: 0.9;
  line-height: 1.4;
  font-size: 1.00rem;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};

  ${(props) => props.theme.breakpoints.up("sm")} {
    font-size: 1.5rem;
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 2.0rem;
  }

  span {
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const Subtitle = styled(Typography)`
  font-size: ${(props) => props.theme.typography.h6.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  font-family: ${(props) => props.theme.typography.fontFamily};
  margin: ${(props) => props.theme.spacing(2)} 0;
`;

const BrandIcon = styled.img`
  vertical-align: middle;
  margin-right: ${(props) => props.theme.spacing(3)};
  height: auto;
`;

const Visibility = styled(VisibilityIcon)`
  margin-right: ${(props) => props.theme.spacing(2)};
`;

const ArrowForward = styled(ArrowForwardIcon)`
  margin-left: ${(props) => props.theme.spacing(2)};
`;

const Version = styled(MuiTypography)`
  color: ${(props) => props.theme.palette.primary.main};
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  background: ${(props) => rgba(props.theme.palette.primary.main, 0.1)};
  box-shadow: 0 1px 1px
    ${(props) => rgba(props.theme.palette.primary.main, 0.25)};
  padding: 3px 8px;
  border-radius: 4px;
  margin-bottom: ${(props) => props.theme.spacing(3)};
  display: inline-block;
`;

const Generic: React.FC<ComponentProps> = ({props}) => {

    // console.log("did it work", props);
    // let arr = pageData?.page?.details?.sections_to_include?.split(',') || ["directions"];
    // let pageTitle = pageData.page?.details.page_title;   
   
    // // let indexes = getAllIndexes(arr, "banner")
    // // console.log("found", indexes)
    // // let exclude = "banner";
    // // arr = arr.filter(item => item !== exclude)
    // // const countries = ['banner_email', 'Sweden',  'banner_nothing', 'banner'];
    
    // // https://stackoverflow.com/questions/40454296/filter-a-list-element-starting-with-a-letter-in-javascript
    // // https://stackoverflow.com/questions/31831651/javascript-filter-array-multiple-conditions
    // const startsWithN = arr.filter((component) => component.startsWith("banner") || component.startsWith("content"));
    // // https://melvingeorge.me/blog/remove-elements-contained-in-another-array-javascript
    // const namesToDeleteSet = new Set(startsWithN);
    // const display = arr.filter((name) => {
    //   // return those elements not in the namesToDeleteSet
    //   return !namesToDeleteSet.has(name);
    // });
    // // console.log(pageData);
    // console.log("COMPONENTS", display);

  return (
    <Wrapper>
      <Container>
        <Grid container alignItems="center" justifyContent="center" spacing={4}>
          <Grid item xs={12} sm={9} md={8} lg={8}>
            <Content>
              <Title variant="h1" gutterBottom>
                {props?.h1}
              </Title>
              <Grid container justifyContent="center" spacing={4}>
                <Grid item xs={12} lg={10}>
                  {/* <Subtitle color="textSecondary"> */}
                      <Markup content={props?.html}/>
                  {/* </Subtitle> */}
                </Grid>
              </Grid>
            </Content>
          </Grid>
        </Grid>

      </Container>
    </Wrapper>
  );
}

export default Generic;
