import Generic from "../components/Generic";
import FAQ from "../components/FAQ";
import ContentPanel from "../components/ContentPanel";
import BannerPanel from "../components/BannerPanel";
import Introduction from "../components/Introduction";
import Projects from "../components/Projects";
import ProjectSearch from "../components/ProjectSearch";
import Fullpage from "../components/Fullpage";
import Pricing from "../components/Pricing";
import { Page, ComponentsEntity } from '../../../types/page'

export const renderSwitch = (type:string, componentData:ComponentsEntity) => {
    switch(type) {
        case 'generic':
            return <Generic key={componentData.name} props={componentData} />;
        case 'frequently_asked_questions':
            return <FAQ key={componentData.name} props={componentData} />;
        case 'content_panel':
            return <ContentPanel key={componentData.name} props={componentData} />;
        case 'banner_panel':
            return <BannerPanel key={componentData.name} props={componentData} />;            
        case 'introduction':
            return <Introduction key={componentData.name} props={componentData} />;
        case 'projects':
            return <Projects key={componentData.name} props={componentData} />;     
        case 'project_search':
            return <ProjectSearch key={componentData.name} props={componentData} />;                         
        case 'fullpage':
            return <Fullpage key={componentData.name} props={componentData} />;
        case 'pricing':
            return <Pricing key={componentData.name} props={componentData} />;                        
        default:
            return '';
    }
}