import React, { useState, useEffect } from 'react';
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";

import { Box, Chip, Container, Grid, Typography } from "@mui/material";
import { spacing } from "@mui/system";

import { THEMES } from "../../../constants";
import useTheme from "../../../hooks/useTheme";
import { Markup } from 'interweave';
import { ComponentProps } from '../../../types/page'
import Divider from '@mui/material/Divider';

const Wrapper = styled.div`
  ${spacing};
  background: ${(props) => props.theme.palette.background.paper};
  text-align: center;
`;

const DemoContent = styled.div(spacing);

const DemoLink = styled.div`
  cursor: pointer;
`;

const DemoImage = styled.img`
  max-width: 90%;
  height: auto;
  
  display: block;
  box-shadow: 0 4px 12px 0 rgba(18, 38, 63, 0.125);
  transition: 0.15s ease-in-out;
  border-radius: 4px;
  
  &:hover {
    transform: scale(1.0325);
  }
`;

const DemoChip = styled(Chip)`
  background-color: ${(props) => props.theme.palette.secondary.main};
  border-radius: 5px;
  color: ${(props) => props.theme.palette.common.white};
  font-size: 55%;
  height: 18px;
  margin-top: -16px;
  padding: 3px 0;

  span {
    padding-left: ${(props) => props.theme.spacing(1.375)};
    padding-right: ${(props) => props.theme.spacing(1.375)};
  }
`;

const TypographyOverline = styled(Typography)`
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.primary.main};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const BoxSpacer = styled.div`
  display: flex;
  text-align: left;
  padding: 18px 20px;
  margin-bottom: 50px;
`;


type VariantProps = {
  theme: string;
  title: string;
  img: string;
  text: string;
  isNew?: boolean;
};



const Variant: React.FC<VariantProps> = ({
  theme,
  title,
  img,
  text,
  isNew = false,
}) => {

  console.log("IMGGE", img);
  const navigate = useNavigate();
  const { setTheme } = useTheme();

  // const toggleDemo = (theme: string) => {
  //   setTheme(theme);
  //   navigate("/dashboard/default");
  // };
  
  return (
    <Grid item xs={10} >
      <BoxSpacer>
        <Typography variant="h2" component="h3" gutterBottom>
          {/* <DemoLink onClick={() => toggleDemo(theme)}> */}
            <DemoImage
              alt={`${title}`}
              src={`${img}`}
            />
          {/* </DemoLink> */}
        </Typography>
        <Box>
          <Typography variant="h1" color="textPrimary">
            {title}
            <Typography variant="subtitle1" color="textSecondary" gutterBottom>
              {text}
            </Typography>
          </Typography>        
        </Box>
        <Divider variant="middle" />
      </BoxSpacer>



      {/* <DemoContent px={2}>
        <DemoLink onClick={() => toggleDemo(theme)}>
          <DemoImage
            alt={`${title}`}
            src={`${img}`}
          />
        </DemoLink>
        <Box mb={3} />
        <Typography variant="h6">
          {title} {isNew && <DemoChip label="New" />}
        </Typography>
        <Typography>
          {text}
        </Typography>
      </DemoContent> */}
    </Grid>
  );
};

type HTMLProps = {
  html: string;
};
const Feature: React.FC<HTMLProps> = ({ html }) => {
  console.log(html)
  return (
    <Grid item xs={12} sm={6} md={4} lg={12}>
      <Box mb={10}>
        <Typography variant="subtitle1" gutterBottom my={4}>
          <Markup content={html}/>
        </Typography>
      </Box>
    </Grid>
  );
};


const BannerPanel: React.FC<ComponentProps> = ({props}) => {

  return (
    <Wrapper pt={16} pb={20} id="demos">
      <Container>
        {/* <TypographyOverline variant="body2" gutterBottom>
          Demos
        </TypographyOverline> */}
        {/* <Typography variant="h2" component="h3" gutterBottom>
          Test
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" gutterBottom>
          Each project is designed to affect our community in different ways.
        </Typography> */}
        <Box mb={8} />

        {
          props?.banners?.map((banner: any, i: number) => {
            console.log("CONTNET", banner)
              return (
                // <div>HI</div>
                // <Variant key={faq.question} question={faq.question} answer={faq.answer}/>
                <Variant key={banner.title} theme={THEMES.DEFAULT} title={banner.title} img={banner.image.src} text={banner.text}/>
              )
            }
          )
        }
        {/* <Grid container spacing={6}> */}

          {/* { 
            props.pages &&
              props.pages.map((page: Page, index: number) => (
                page.body?.map((c: any, i: number) => {
                    if (c.name === "banner" && c.type == "images")
                      return (
                        c.images?.map((img: any, i: number) => {
                          console.log("images", img);
                          return (
                            // <div>HI</div>
                            <Variant key={img.title} theme={THEMES.DEFAULT} title={img.title} img={img.src} description={img.description}/>
                          )
                        }
                      )
                    )
                  }
                )
            ))
          } */}
        

        {/* <Grid container spacing={10}>
           <Variant
            theme={THEMES.DEFAULT}
            title="Default variant"
            img="image1"
          />
          <Variant theme={THEMES.BLUE} title="Blue variant" img="image2" />
          <Variant theme={THEMES.DARK} title="Dark variant" img="image3" isNew />
          {/* <Variant theme={THEMES.GREEN} title="Green variant" img="green" />
          <Variant theme={THEMES.INDIGO} title="Indigo variant" img="indigo" />
          <Variant theme={THEMES.LIGHT} title="Light variant" img="light" />
        </Grid> */}
      </Container>
    </Wrapper>
  );
}

export default BannerPanel;
