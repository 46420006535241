import React, { useState, useEffect } from 'react';
import styled from "styled-components/macro";
import axios from 'axios';
import { rgba } from "polished";
import {
    Box,
    Button,
    Container,
    Grid,
    Tooltip,
    Typography,
  } from "@mui/material";
import { Card, Input } from 'semantic-ui-react'
import TextField from "@mui/material/TextField";
import {
    Mail as MailIcon,
    Code as CodeIcon,
    Users as UsersIcon,
    Figma as FigmaIcon,
    BookOpen as BookOpenIcon,
    PlusCircle as PlusCircleIcon,
  } from "react-feather";
  
import apiService from "../../../app/services/api";
import { Markup } from 'interweave';
import { NavLink } from "react-router-dom";
import { ArrowForward as ArrowForwardIcon } from "@mui/icons-material";
import { ComponentProps } from '../../../types/page'
import { Project, ProjectDetails, ProjectProps } from '../../../types/project'
import { array } from 'yup/lib/locale';
import Divider from '@mui/material/Divider';

const Wrapper = styled.div`
  padding-top: 3.5rem;
  position: relative;
  text-align: center;
  overflow: hidden;
`;

const FeatureWrapper = styled.div`
  display: flex;
  text-align: left;
  padding: 18px 20px;
`;

const FeatureIcon = styled.div`
  svg {
    flex-shrink: 0;
    width: auto;
    height: 48px;
    width: 48px;
    background: ${(props) => rgba(props.theme.palette.primary.main, 0.15)};
    color: ${(props) => props.theme.palette.primary.main};
    padding: 10px;
    border-radius: 50%;
  }
`;

const Title = styled(Typography)`
  opacity: 0.9;
  line-height: 1;
  font-size: 1.75rem;
  margin-top:50px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};

  ${(props) => props.theme.breakpoints.up("sm")} {
    font-size: 1.5rem;
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 2rem;
  }

  span {
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;
const ArrowForward = styled(ArrowForwardIcon)`
  margin-left: ${(props) => props.theme.spacing(2)};
`;

type FeatureProps = {
    icon: string;
    // Icon: React.ElementType;
    title: string;
    description: string;
    key: string;
  };

const Feature: React.FC<FeatureProps> = ({ icon, title, description }) => {
    let Icon: React.ElementType;

    console.log("HELLO", icon)

    switch (icon) {
      case "giving":
        Icon = MailIcon
        break;
      case "food":
        Icon = CodeIcon
        break;
      case "giving":
        Icon = UsersIcon
        break;
      case "provider":
        Icon = FigmaIcon
        break;
      case "skills":
        Icon = BookOpenIcon
        break;
      case "encouragement":
        Icon = PlusCircleIcon
        break;
      default:
        Icon = MailIcon
    }
    return (
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <FeatureWrapper>
          <FeatureIcon>
            <Icon />
          </FeatureIcon>
          <Box ml={6}>
            <Typography variant="h4" gutterBottom>
              {title}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              <Markup content={description}/>
            </Typography>
          </Box>
        </FeatureWrapper>
      </Grid>
    );
  };

const Projects: React.FC<ComponentProps> = ({props}) => {
// export default function Posts() {
    const [APIData, setAPIData] = useState([] as any)
    const [filteredResults, setFilteredResults] = useState([] as any);
    const [searchInput, setSearchInput] = useState('');
    const project_arr:string[] = [];
    let projects = props?.projects;

    projects?.forEach(function (project) {
      project_arr.push(project.link_to_content as string)
    }); 

    // for (var [i:number] in projects){
    //   console.log(i);
    //   // const project = projects[i as number];
    //   // arr.push(project.main)
    // }

    useEffect(() => {
      apiService.getProjects().then((results) => {
          let project_complete: any[] = [];
          for (let x in results) {
            // console.log("project", results[x]);

            // FILTERS OUT THE BAD PROJECTS THAT WE DO NOT WHAT TO DISPLAY
            if(results[x].projectStatus != "ERROR" && results[x].projectStatus.length > 0){
              let project_id = results[x].projectId;
              if (project_arr.includes(project_id)){
                project_complete.push(results[x]);
              }
            }
          }
          setAPIData(project_complete);
        });
    }, [])
    
    // const searchItems = (searchValue: string) => {
    //     setSearchInput(searchValue)
    //     if (searchInput !== '') {
    //         const filteredData = APIData.filter((item:any) => {
    //             return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
    //         })
    //         setFilteredResults(filteredData)
    //     }
    //     else{
    //         setFilteredResults(APIData)
    //     }
    // }

    return (
        <Wrapper>
            <Container sx={{pb:10}}>
                <Grid container alignItems="center" justifyContent="center" spacing={4} >
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            {/* <TextField
                                id="search-bar"
                                className="text"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => searchItems(e.target.value)}
                                label="Enter a project name"
                                variant="outlined"
                                placeholder="Search..."
                                size="small"
                            /> */}
                          <Divider variant="middle" />
                          <Title variant="h1" gutterBottom>
                            {props?.name}
                          </Title>
                          
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={9} md={8} lg={12}> 
                        <div style={{ padding: 5 }}>
                            <Grid container spacing={8}>
                                {
                                APIData &&
                                  APIData.map((item:Project) => {
                                      return (
                                          <Feature
                                              key={item.projectId}
                                              icon={item.details.category}
                                              title={item.projectName}
                                              description={item.body.short_read}
                                          />
                                      )
                                  })
                                }
                            </Grid>                            
                        </div>
                    </Grid>
                    <Box mt={4}>
                      <Button
                        component={NavLink}
                        to="/project_search"
                        variant="contained"
                        color="secondary"
                        size="large"
                      >
                        Open Projects
                        <ArrowForward />
                      </Button>
                    </Box>
                </Grid>                    
            </Container>
        </Wrapper>        
    )
}

export default Projects;