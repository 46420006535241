import { useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { Formik, Form } from "formik";

import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";

import useAuth from "../../hooks/useAuth";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface stateType {
  email: string;
}

// function Verify() {
const Verify: React.FC = (props) => {  
  const navigate = useNavigate();
  const data = useLocation().state as stateType;
  const { verifyAccount } = useAuth();
  return (
    <Formik
      initialValues={{
        email: data.email,
        OTP: "",
        submit: false,
      }}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          verifyAccount(
            values.email,
            values.OTP,
          );
          navigate("/auth/sign-in");
        } catch (error: any) {
          const message = error.message || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            type="email"
            name="email"
            label="Email address"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />          
          <TextField
            type="text"
            name="OTP"
            label="Enter the verification code:"
            value={values.OTP}
            error={Boolean(touched.OTP)}
            fullWidth
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Verify
          </Button>
        </Form>
      )}
    </Formik>
  );
}

export default Verify;
