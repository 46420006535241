import React, { useState, useEffect } from 'react';
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Avatar,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardActions,
  CardContent as MuiCardContent,
  CardMedia as MuiCardMedia,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography as MuiTypography,
} from "@mui/material";
import { AvatarGroup as MuiAvatarGroup } from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import apiService from "../../app/services/api";
import { Markup } from 'interweave';
import { Project, ProjectDetails, ProjectProps } from '../../types/project'
import { StringLiteralLike } from 'typescript';

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)`
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
`;

const CardMedia = styled(MuiCardMedia)`
  height: 220px;
`;

const Divider = styled(MuiDivider)(spacing);

interface TypographyProps extends SpacingProps {
  component?: string;
}
const Typography = styled(MuiTypography)<TypographyProps>(spacing);

const Chip = styled(MuiChip)<{ color?: string }>`
  height: 20px;
  padding: 4px 0;
  font-size: 85%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
  margin-bottom: ${(props) => props.theme.spacing(4)};
`;

const AvatarGroup = styled(MuiAvatarGroup)`
  margin-left: ${(props) => props.theme.spacing(2)};
`;




const ProjectCard: React.FC<ProjectProps> = ({
  image,
  projectId,
  projectTitle,
  projectName,
  description,
  chip,
  web_view_link,
}) => {
  return (
    <Card>
      {image ? <CardMedia image={image} title="Contemplative Reptile" /> : null}
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {projectName}
        </Typography>

        {chip}

        <Typography mb={4} color="textSecondary" component="span">
          <Markup content={description}/>
        </Typography>

        {/* <AvatarGroup max={3}>
          <Avatar alt="Avatar" src="/static/img/avatars/avatar-1.jpg" />
          <Avatar alt="Avatar" src="/static/img/avatars/avatar-2.jpg" />
          <Avatar alt="Avatar" src="/static/img/avatars/avatar-3.jpg" />
        </AvatarGroup> */}
      </CardContent>
      <CardActions>
        <Button size="small" color="primary">
          Share
        </Button>
        <Button size="small" color="primary">
          Learn More
        </Button>
        <a href={web_view_link} target="_blank">Google doc</a>
      </CardActions>
    </Card>
  );
};

  function status_icon(item:Project){    
    let status:JSX.Element;
    switch (item.projectStatus[0]) {
      case "completed":
        status = <Chip label={item.projectStatus[0]} color="success" />
        break;
      case "pending":
        status = <Chip label={item.projectStatus[0]} color="warning" />
        break;
      case "hidden":
        status = <Chip label={item.projectStatus[0]} color="info" />
        break;
      case "draft":
        status = <Chip label={item.projectStatus[0]} color="info" />
        break;
      default:
        status = <Chip label={"ERROR"} color="error" />
    };
    return status;
  };


  function Projects() {

    const [APIData, setAPIData] = useState([])
    const [filteredResults, setFilteredResults] = useState([]);
    const [searchInput, setSearchInput] = useState('');

    useEffect(() => {
      apiService.getProjects().then((results) => {
        console.log("RESULTS", results)
          // for (let x in results.Items) {
            // console.log("CATEGORY", results.Items[x].details.category)
          // }
          setAPIData(results);
        });
    }, [])
    
    const searchItems = (searchValue: string) => {
      setSearchInput(searchValue)
      if (searchInput !== '') {
          const filteredData = APIData.filter((item) => {
              return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
          })
          setFilteredResults(filteredData)
      }
      else{
          setFilteredResults(APIData)
      }
  }


  return (
    <React.Fragment>
      <Helmet title="Projects" />

      <Typography variant="h3" gutterBottom display="inline">
        Projects
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/">
          Pages
        </Link>
        <Typography>Projects</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <Grid container spacing={6}>
        <div style={{ padding: 5 }}>
            <Grid container spacing={8}>
                {searchInput.length > 1 ? (
                    filteredResults.map((item:Project) => {
                        let status:JSX.Element;
                        status = status_icon(item);
                        return (
                          <Grid item xs={12} lg={6} xl={3}>
                            <ProjectCard
                                key={item.projectId}
                                projectId={item.projectId}
                                projectTitle={item.projectTitle}
                                projectName={item.projectName}
                                description={item.body.short_read}
                                chip={status}
                                web_view_link={item.web_view_link}
                              />
                          </Grid>
                        )
                    })
                ) : (
                    APIData.map((item:Project) => {
                        let status:JSX.Element;
                        status = status_icon(item);
                        return (
                            <Grid key={item.projectId} item xs={12} lg={6} xl={3}>
                              <ProjectCard
                                  key={item.projectId}
                                  projectId={item.projectId}
                                  projectTitle={item.projectTitle}
                                  projectName={item.projectName}
                                  description={item.body.short_read}
                                  chip={status}
                                  web_view_link={item.web_view_link}
                                />
                            </Grid>
                        )
                    })
                )}
            </Grid>
        </div>
      </Grid>
    </React.Fragment>
  );
}

export default Projects;
