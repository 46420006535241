import React, { useState, useEffect } from 'react';
import styled from "styled-components/macro";

import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Container,
  Grid,
  Typography,
  Link,
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { Markup } from 'interweave';
import { ComponentProps } from '../../../types/page'

const Spacer = styled.div(spacing);

const Wrapper = styled.div`
  ${spacing};
  background: ${(props) => props.theme.palette.background.paper};
  text-align: center;
`;

const TypographyOverline = styled(Typography)`
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.primary.main};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const Accordion = styled(MuiAccordion)`
  border-radius: 6px;
  text-align: left;
  margin: 20px 0 !important;
  box-shadow: 0 5px 10px 0 rgba(18, 38, 63, .30);

  &:before {
    display: none;
  }
`;

const AccordionSummary = styled(MuiAccordionSummary)`
  padding: 0 16px;
  box-shadow: 0;
  min-height: 48px !important;

  .MuiAccordionSummary-content {
    margin: 12px 0 !important;
  }
`;

const AccordionDetails = styled(MuiAccordionDetails)`
  padding-left: 16px;
  padding-right: 16px;
`;


type VariantProps = {
  question: string;
  answer: string;
};

const Variant: React.FC<VariantProps> = ({
  question,
  answer,
}) => {

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="faq1-content"
        id="faq1-header"
      >
        <Typography variant="subtitle1">
          {question}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="subtitle1" color="textSecondary">
          {answer}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};
const FAQ: React.FC<ComponentProps> = ({props}) => {
// function FAQ(props:PageProps) {
  return (
    <Wrapper pt={20} pb={16}>
      <Container>
          <TypographyOverline variant="body2" gutterBottom>
            THE OVIS PROJECT
          </TypographyOverline>
          <Typography variant="h2" component="h3" gutterBottom>
            Frequently asked questions
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" gutterBottom>
            The questions below have been selected from those most commonly asked
            by our customers.
          </Typography>
          <Spacer mb={8} />

          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={12} xl={8}>
              { 
                  props?.faqs?.map((faq: any, i: number) => {
                    return (
                      // <div>HI</div>
                      <Variant key={faq.question} question={faq.question} answer={faq.answer}/>
                    )
                  }
                )
              }
            </Grid>
          </Grid>
      </Container>
    </Wrapper>
  );
}

export default FAQ;
