import React, { useState, useEffect } from 'react';
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import axios from 'axios';
import {
  Avatar,
  Breadcrumbs as MuiBreadcrumbs,
  Box,
  Button,
  Card as MuiCard,
  CardActions,
  CardContent as MuiCardContent,
  CardMedia as MuiCardMedia,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  Link,
  TextField,
  Typography as MuiTypography,
} from "@mui/material";
import { AvatarGroup as MuiAvatarGroup } from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import apiService from "../../app/services/api";
import { Markup } from 'interweave';
import { Project, ProjectDetails, ProjectProps } from '../../types/project'
import { StringLiteralLike } from 'typescript';
import Recorder from "./Recorder";
import "./Inventory.css"
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import FilledInput from '@mui/material/FilledInput';
import InventoryItem from './InventoryItem';
import { ImageProps, InventoryImage } from '../../types/page'

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)`
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
`;


const CardMedia = styled(MuiCardMedia)`
  height: 220px;
`;

const Divider = styled(MuiDivider)(spacing);

interface TypographyProps extends SpacingProps {
  component?: string;
}
const Typography = styled(MuiTypography)<TypographyProps>(spacing);

const Chip = styled(MuiChip) <{ color?: string }>`
  height: 20px;
  padding: 4px 0;
  font-size: 85%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
  margin-bottom: ${(props) => props.theme.spacing(4)};
`;

const AvatarGroup = styled(MuiAvatarGroup)`
  margin-left: ${(props) => props.theme.spacing(2)};
`;

const ProjectCard: React.FC<ProjectProps> = ({
  image,
  projectId,
  projectTitle,
  projectName,
  description,
  chip,
  web_view_link,
}) => {
  return (
    <Card>
      {image ? <CardMedia image={image} title="Contemplative Reptile" /> : null}
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {projectName}
        </Typography>

        {chip}

        <Typography mb={4} color="textSecondary" component="span">
          <Markup content={description} />
        </Typography>

        {/* <AvatarGroup max={3}>
          <Avatar alt="Avatar" src="/static/img/avatars/avatar-1.jpg" />
          <Avatar alt="Avatar" src="/static/img/avatars/avatar-2.jpg" />
          <Avatar alt="Avatar" src="/static/img/avatars/avatar-3.jpg" />
        </AvatarGroup> */}
      </CardContent>
      <CardActions>
        <Button size="small" color="primary">
          Share
        </Button>
        <Button size="small" color="primary">
          Learn More
        </Button>
        <a href={web_view_link} target="_blank">Google doc</a>
      </CardActions>
    </Card>
  );
};

function status_icon(item: Project) {
  let status: JSX.Element;
  switch (item.projectStatus[0]) {
    case "completed":
      status = <Chip label={item.projectStatus[0]} color="success" />
      break;
    case "pending":
      status = <Chip label={item.projectStatus[0]} color="warning" />
      break;
    case "hidden":
      status = <Chip label={item.projectStatus[0]} color="info" />
      break;
    case "draft":
      status = <Chip label={item.projectStatus[0]} color="info" />
      break;
    default:
      status = <Chip label={"ERROR"} color="error" />
  };
  return status;
};

const isObject = (obj: any) => {
  return typeof obj === 'object' && obj !== null && !Array.isArray(obj)
}
type Image = {
  id: number;
  [key: string]: any;
}

function Inventory() {
  const [PicData, setPicData] = useState("")
  const [APIData, setAPIData] = useState<InventoryImage[]>([])
  const [filteredResults, setFilteredResults] = useState([]);
  const [searchInput, setSearchInput] = useState('');

  // const onUpload = () => {
  //   let query = "bucket_name=ovispublish&object_key=luke.fleshman@gmail.com/box_aardvark/52.image0.jpeg"
  //   let url = "https://api.ovisproject.org/v1/ovis/app/uploads/assets?" + query;
  //   let result = {};
  //   // for(var i in form_files){      
  //   //   let file_data = form_files[i];
  //   //   if(isObject(file_data)){
  //     axios.post(url)
  //     .then(response => {
  //       console.log("RESPONSE", response);
  //       setPicData(response.data)
  //       // result = uploadFileToS3(response.data, file_data);
  //     })
  //     .catch(error => {
  //       console.log(JSON.stringify(error));
  //     })   
  //   //   }
  //   // };
  //   return result;
  // }; 


  useEffect(() => {
    apiService.getInventoryImages().then((results) => {
      console.log("RESULTS", JSON.parse(results))
      var data = JSON.parse(results)
      var loopData = ''
      var i;
      let boxes: InventoryImage[] = [];
      console.log("length", data);

      for (i = 0; i < data.length; i++) {
        console.log(data[i].url)
        let image = {
          id: i,
          bucket_name: data[i].bucket_name,
          epoch: data[i].epoch,
          expiry: data[i].expiry,
          key: data[i].key,
          url: data[i].url
        };
        boxes.push(image)
      }
      console.log(boxes);
      // for (let x in results) {
      //   for (let x in results) {
      //     console.log("CATEGORY", x)
      //   }
      // }
      setAPIData(boxes);
      // console.log(APIData);
      // onUpload();


    });
  }, [])

  //   const searchItems = (searchValue: string) => {
  //     setSearchInput(searchValue)
  //     if (searchInput !== '') {
  //         const filteredData = APIData.filter((item) => {
  //             return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
  //         })
  //         setFilteredResults(filteredData)
  //     }
  //     else{
  //         setFilteredResults(APIData)
  //     }
  // }


  return (
    <React.Fragment>
      <Helmet title="Inventory" />

      <Typography variant="h3" gutterBottom display="inline">
        Projects
      </Typography>
      {PicData && (<img src={PicData} />)}
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/">
          Pages
        </Link>
        <Typography>Projects</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <Grid id="images" container spacing={6}>
        <div style={{ padding: 5 }}>

          <Grid container spacing={8}>
            <Grid item xs={12} lg={6} xl={3}>
              <div >
                <Grid container direction={"column"} spacing={5}>
                  <Grid item>
                    <TextField label="First Name" variant="outlined" /><TextField label="Last Name" variant="outlined" />
                  </Grid>
                  <Grid item>
                    <TextField label="Email" variant="outlined" />
                  </Grid>
                </Grid>

                {/* <TextField label="Email Address" id="outlined-size-normal" defaultValue="Normal" />
                    <TextField label="Amount" id="outlined-size-normal" defaultValue="Normal" /> */}
              </div>
              {
                APIData &&
                <div>

                  {APIData.map((image, index) => (
                    <InventoryItem key={image.id} props={image}></InventoryItem>
                  ))}
                </div>
              }
            </Grid>
          </Grid>
        </div>
      </Grid>

    </React.Fragment>
  );
}

export default Inventory;
