import React, { useState, useEffect } from 'react';
import apiService from "../../../app/services/api";
import AppBar from "../components/AppBar";
import Footer from "../components/Footer";
import { Page } from '../../../types/page'
import { Helmet } from "react-helmet-async";
import { 
  renderSwitch
  } from "../components/Switcher"

  import {
    Box,
    Button,
    Container,
    Grid,
    Tooltip,
    Typography as MuiTypography,
  } from "@mui/material";
import ContactUsForm from './ContactUsForm'

function Presentation() {

  const [pageData, setPageData] = useState<Page>()
  useEffect(() => {
    apiService.getPages().then((results) => {
        var pages = results["Items"];
        for (let x in pages) {
          if (pages[x].pageName === "contactus") {
            setPageData(pages[x]);
          }
        }
      });
  }, [])
  return (
    <React.Fragment>
      <AppBar />
      <Helmet title="Contact Us" />
      <Grid container alignItems="center" justifyContent="center" spacing={4}>
          <Grid item xs={8} sm={9} md={10} lg={11}>
            {
            pageData && 
              pageData.components?.map((c:any, i: number) => {
                return renderSwitch(c.type, c)
              })
            }      
            
          </Grid>
          <Grid item xs={12} sm={7} md={8} lg={9}>
            <ContactUsForm />     
            
          </Grid>          
        </Grid>

      
      {/*<Mission />*/}
      
      {/*<Testimonial />
      <Integrations /> */}
      {/* <Fullpage page={pageData} />
      <Banner page={pageData} /> */}
      {/* <FAQ />
      <JoinUs /> */}
      <Footer />
    </React.Fragment>
  );
}

export default Presentation;
