import React, { useRef } from 'react';
import { render } from 'react-dom'
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Newsletter from "./GrapeComponent";
import {
  Button,
  CardActions,
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardHeader as MuiCardHeader,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { StarBorder as StarIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const CardHeader = styled(MuiCardHeader)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Price = styled.div`
  text-align: center;
  padding-bottom: ${(props) => props.theme.spacing(3)};
`;


const Header = styled.div`
  padding: ${(props) => props.theme.spacing(6)} 0;
`;

function Mailer() {
  return (
    <React.Fragment>
      <Helmet title="Pricing" />
      <Newsletter></Newsletter>
      {/* <Typography variant="h3" gutterBottom display="inline">
        Mailer
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/">
          Pages
        </Link>
        <Typography>Email Template Generator</Typography>
      </Breadcrumbs>

      <Divider my={6} /> */}

      {/* <Header>
        <Typography variant="h3" gutterBottom align="center">
          We have a plan for everyone
        </Typography>

        <Typography variant="subtitle1" gutterBottom align="center">
          Whether you're a business or an individual, 14-day trial no credit
          card required.
        </Typography>
      </Header> */}

      {/* <Grid container justifyContent="center">
        <Grid item xs={12} lg={12}>
          <Grid container spacing={12} alignItems="flex-end">
            <Grid item xs={12} md={12}>
              <Card p={5}>
                <CardContent>
                  
                  
                </CardContent>
                <CardActions>
                  {/* <Button fullWidth variant="outlined" color="primary">
                    Sign up for free
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid> */}
    </React.Fragment>
  );
}

export default Mailer;
