import React, { useState, useEffect } from 'react';
import AppBar from "../components/AppBar";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet-async";
import apiService from "../../../app/services/api";
import { 
  renderSwitch
  } from "../components/Switcher"
import { Page } from '../../../types/page'

function Presentation() {
  const [pageData, setPageData] = useState<Page>()
  useEffect(() => {
    apiService.getPages().then((results) => {
        var pages = results["Items"];
        for (let x in pages) {
          if (pages[x].pageName === "landing") {            
            setPageData(pages[x]);
          }
        }
      });
  }, [])
  return (
    <React.Fragment>
      <AppBar />
      <Helmet title="Home" />
      {
      pageData && 
        pageData.components?.map((c:any, i: number) => {
          return renderSwitch(c.type, c)
        })
      }
      <Footer></Footer>
    </React.Fragment>
  );
}

export default Presentation;
