import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
// import LoadingIndicator from "../../../../common/components/LoadingIndicator";

import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
} from "@mui/material";
// import { DataGridPro, GridColDef, LicenseInfo } from "@mui/x-data-grid-pro";
import { spacing } from "@mui/system";

// import { DataGrid } from "../../../../common/components/DataGrid";

// import CmdbApi from "../api";
import apiService from "../../../app/services/api";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

// const columns: GridColDef[] = [
//   { field: "id", headerName: "ID", width: 150 },
//   {
//     field: "firstName",
//     headerName: "First name",
//     width: 200,
//     editable: false,
//   },
//   {
//     field: "lastName",
//     headerName: "Last name",
//     width: 200,
//     editable: false,
//   },
//   {
//     field: "age",
//     headerName: "Age",
//     type: "number",
//     width: 150,
//     editable: false,
//   },
// ];

const rows = [
  { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
  { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
  { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
  { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
  { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
  { id: 6, lastName: "Melisandre", firstName: null, age: 150 },
  { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
  { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
  { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 },
];

function Test() {
  // const cmdbApi = new CmdbService();
  const [isLoading, setLoading] = useState(true);
  const [results, setResults] = useState(null);
  const [config, setConfig] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    // getData();
    setLoading(false);
  }, []);

  // This function will load/refresh data for table
  // function getData() {
  //   apiService.getTest().then((results) => {
  //   //   setConfig(results[0].dataset.config);
  //   //   setData(results[0].dataset.data);
  //     console.log(results);
  //     // console.log(config);
  //   });
  // }

  return config && data ? (
    <React.Fragment>
      <Helmet title="Seneca - Explore" />
      <Typography variant="h3" gutterBottom display="inline">
        Explore - ECR
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Home
        </Link>
        <Link component={NavLink} to="/apps/explorer">
          Explore
        </Link>
        <Typography>ECR Findings</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      {/* <DataGrid rows={data} columns={config} /> */}
    </React.Fragment>
  ) : (
      null
    // <LoadingIndicator loading={true} />
  );
}

export default Test;
