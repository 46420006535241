import * as React from 'react';
// import AppBar from '@mui/material/AppBar';
// import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
// import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
// import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { spacing, SpacingProps } from "@mui/system";
import { ReactComponent as Logo } from "../../../vendor/logo.svg";
import {
  AppBar,
  Button as MuiButton,
  Container,
  Grid,
  Box,
  Toolbar,
} from "@mui/material";
import useAuth from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";


interface ButtonProps extends SpacingProps {
  component?: React.ElementType;
  to?: string;
  target?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);
const ListItemButton = styled(MuiButton)<ButtonProps>(spacing);
const drawerWidth = 240;
const navItems = [
  {
    "title": "Home",
    "url": "/"
  }, {
    "title": "Projects",
    "url": "/project_search"
  }, {
    "title": "Contribution",
    "url": "/contribution"
  }, {
    "title": "Contact Us",
    "url": "/contact_us"
  }, {
    "title": "Donate",
    "url": "/donate"
  }, {
    "title": "Dashboard",
    "url": "/dashboard/default"
  }]

const Brand = styled.div`
    font-size: ${(props) => props.theme.typography.h5.fontSize};
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
    font-family: ${(props) => props.theme.typography.fontFamily};
`;

const BrandIcon = styled(Logo)`
    margin-right: ${(props) => props.theme.spacing(2)};
    margin-top: -2px;
    color: ${(props) => props.theme.sidebar.header.brand.color};
    fill: ${(props) => props.theme.sidebar.header.brand.color};
    width: 32px;
    height: 32px;

    vertical-align: middle;
    display: inline;
`;
// const navItems = ['Home', 'About', 'Contact'];

// export default function DrawerAppBar(props: Props) {
const AppBarComponent: React.FC = () => {
  //   const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();
  const { isAuthenticated, isInitialized, signOut } = useAuth();

  const handleSignOut = async () => {
    await signOut();
    navigate("/");
  };


  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      {/* <Typography variant="h6" sx={{ my: 2 }}>
        MUI
      </Typography> */}
      <Divider />
      <List>
        {

          navItems.map((item) => (
            <ListItem key={item.title} disablePadding>
              <ListItemButton sx={{ textAlign: 'center' }}
                component={Link}
                to={item.url}
                color="inherit"
              >
                <ListItemText primary={item.title} />
              </ListItemButton>
            </ListItem>
          ))}

        {
          isInitialized && !isAuthenticated ?
            <ListItem key="Login" disablePadding>
              <ListItemButton sx={{ textAlign: 'center' }}
                href="/auth/sign-in"
                color="inherit"
              >
                <ListItemText primary="Login" />
              </ListItemButton>
            </ListItem>
            :
            <ListItem key="Logout" disablePadding>
              <ListItemButton sx={{ textAlign: 'center' }}
                onClick={handleSignOut}
                color="inherit"
              >
                <ListItemText primary="Logout" />
              </ListItemButton>
            </ListItem>
        }
      </List>
    </Box>
  );

  //   const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="relative" color="transparent" elevation={0}>
        <Toolbar>
          <Container>
            <Grid container alignItems="center">
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2, display: { md: 'none' } }}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <Brand>
                  <Button
                    ml={2}
                    color="inherit"
                    sx={{ fontSize: 20 }}
                    component={Link}
                    to="/"
                  >
                    <BrandIcon />
                    OVIS PROJECT
                  </Button>

                </Brand>
              </Grid>
              <Grid item xs />
              <Grid item>
                <Box sx={{ display: { xs: "none", md: "inline-block" } }}>
                  {navItems.map((item) => (
                    <Button key={item.title}
                      ml={2}
                      color="inherit"
                      component={Link}
                      to={item.url}>
                      {item.title}
                    </Button>
                  ))}

                  {isInitialized && !isAuthenticated ?
                    <Button
                      ml={2}
                      color="primary"
                      variant="contained"
                      href="/auth/sign-in"
                    >
                      Login
                    </Button>
                    :
                    <Button onClick={handleSignOut}
                      ml={2}
                      color="primary"
                      variant="contained"
                    >
                      Logout
                    </Button>
                  }

                </Box>
              </Grid>
            </Grid>
          </Container>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          //   container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
export default AppBarComponent;
