import React, { useState, useEffect } from 'react';
import styled from "styled-components/macro";
import axios from 'axios';
import { rgba } from "polished";
import {
    Box,
    Button,
    Container,
    Grid,
    Tooltip,
    Typography,
  } from "@mui/material";


import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Switch from '@mui/material/Switch';


import { Card, Input } from 'semantic-ui-react'
import TextField from "@mui/material/TextField";
import {
    Mail as MailIcon,
    Code as CodeIcon,
    Users as UsersIcon,
    Figma as FigmaIcon,
    BookOpen as BookOpenIcon,
    PlusCircle as PlusCircleIcon,
  } from "react-feather";
  
import apiService from "../../../app/services/api";
import { Markup } from 'interweave';
import { NavLink } from "react-router-dom";
import { ArrowForward as ArrowForwardIcon } from "@mui/icons-material";
import { Project, ProjectDetails, ProjectProps } from '../../../types/project'
import { ComponentProps } from '../../../types/page'
import Divider from '@mui/material/Divider';

const Wrapper = styled.div`
  padding-top: 3.5rem;
  position: relative;
  text-align: center;
  overflow: hidden;
`;

const FeatureWrapper = styled.div`
  display: flex;
  text-align: left;
  padding: 18px 20px;
`;

const FeatureIcon = styled.div`
  svg {
    flex-shrink: 0;
    width: auto;
    height: 48px;
    width: 48px;
    background: ${(props) => rgba(props.theme.palette.primary.main, 0.15)};
    color: ${(props) => props.theme.palette.primary.main};
    padding: 10px;
    border-radius: 50%;
  }
`;

const BoxSpacer = styled.div`
  display: flex;
  text-align: left;
  padding: 18px 20px;
  margin-bottom: 50px;
`;

const ArrowForward = styled(ArrowForwardIcon)`
  margin-left: ${(props) => props.theme.spacing(2)};
`;

type FeatureProps = {
    icon: string;
    // Icon: React.ElementType;
    title: string;
    short: string
    description: string;
    key: string;
  };

const ProjectFeature: React.FC<FeatureProps> = ({ icon, title, short, description }) => {
  const [open, setOpen] = React.useState(false);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('md');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        Details
      </Button>
      <Dialog
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            
          </DialogContentText>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            }}
          >
            <Markup content={description}/>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}


const Feature: React.FC<FeatureProps> = ({ icon, title, short, description }) => {
    let Icon: React.ElementType;

    switch (icon) {
      case "giving":
        Icon = MailIcon
        break;
      case "food":
        Icon = CodeIcon
        break;
      case "giving":
        Icon = UsersIcon
        break;
      case "provider":
        Icon = FigmaIcon
        break;
      case "skills":
        Icon = BookOpenIcon
        break;
      case "encouragement":
        Icon = PlusCircleIcon
        break;
      default:
        Icon = MailIcon
    }
    return (
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <FeatureWrapper>
          <FeatureIcon>
            <Icon />
          </FeatureIcon>
          <Box ml={6}>
            <Typography variant="h4" gutterBottom>
              {title} 
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              <Markup content={short}/>
            </Typography>
            <ProjectFeature key={title} icon={icon} title={title} short={short} description={description}></ProjectFeature>
          </Box>
        </FeatureWrapper>
      </Grid>
    );
  };

const ProjectSearch: React.FC<ComponentProps> = ({props}) => {
    const [APIData, setAPIData] = useState([] as any)
    const [filteredResults, setFilteredResults] = useState([] as any);
    const [searchInput, setSearchInput] = useState('');

    useEffect(() => {
      apiService.getProjects().then((results) => {
          let project_complete: any[] = [];
          for (let x in results) {
            // FILTERS OUT THE BAD PROJECTS THAT WE DO NOT WHAT TO DISPLAY
            if(results[x].projectStatus != "ERROR" && results[x].projectStatus.length > 0){
              if(!results[x].projectStatus.includes("hidden")){
                project_complete.push(results[x]);
              }
            }
          }
          setAPIData(project_complete);
        });
    }, [])
    
    const searchItems = (searchValue: string) => {
        setSearchInput(searchValue)
        if (searchInput !== '') {
            const filteredData = APIData.filter((item:any) => {
                return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
            })
            setFilteredResults(filteredData)
        }
        else{
            setFilteredResults(APIData)
        }
    }

    return (
        <Wrapper>
            <Container>
                <BoxSpacer>
                  <Typography variant="h2" component="h3" gutterBottom>
                    {props?.content?.h2}
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                    {props?.content?.body}
                  </Typography>
                  <Divider variant="middle" />
                </BoxSpacer>
                <Grid container alignItems="center" justifyContent="center" spacing={4}>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <TextField
                                id="search-bar"
                                className="text"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => searchItems(e.target.value)}
                                label="Enter a project name"
                                variant="outlined"
                                placeholder="Search..."
                                size="small"
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={9} md={8} lg={12}> 
                        <div style={{ padding: 5 }}>
                            <Grid container spacing={8}>
                                {searchInput.length > 1 ? (
                                    filteredResults.map((item:Project) => {
                                        return (
                                            <Feature
                                                key={item.projectId}
                                                icon={item.details.category}
                                                title={item.projectName}
                                                short={item.body.short_read}
                                                description={item.body.description}
                                            />
                                        )
                                    })
                                ) : (
                                    APIData.map((item:Project) => {
                                        return (
                                            <Feature
                                                key={item.projectId}
                                                icon={item.details?.category}
                                                title={item.projectName}
                                                short={item.body.short_read}
                                                description={item.body.description}
                                            />
                                        )
                                    })
                                )}
                            </Grid>                            
                        </div>
                    </Grid>
                    {/* <Box mt={4}>
                      <Button
                        component={NavLink}
                        to="/documentation/welcome"
                        variant="contained"
                        color="secondary"
                        size="large"
                        target="_blank"
                      >
                        Open Projects
                        <ArrowForward />
                      </Button>
                    </Box> */}
                </Grid>                    
            </Container>
        </Wrapper>        
    )
}

export default ProjectSearch