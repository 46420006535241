import { useState } from "react";
import axios from 'axios';
import styled from "styled-components/macro";
// import gjsWebpage from "grapesjs-preset-webpage";
import gjsWebpage from "grapesjs-preset-newsletter";
// import grapesJSMJML from 'grapesjs-mjml'
import gjsBlock from "grapesjs-blocks-basic";
// import gjs-plugin-s3 from "grapesjs-plugin-s3";
import "grapesjs/dist/css/grapes.min.css";
import { GrapesjsReact } from "grapesjs-react";
// import { data } from "../dashboards/Default/BarChart";
// import chartLibComponent from "../../editor/plugins/charts";
// import testplugin from "../../editor/plugins/testplugin";
// import gjsForms from 'grapesjs-plugin-forms';
// import gjsTailwind from 'grapesjs-tailwind';

const isObject = obj => {
  return typeof obj === 'object' && obj !== null && !Array.isArray(obj)
}

const Spacer = styled.div`
  width: auto;
  height: auto;
  min-height: auto;
`;

function Newsletter() {
  const [editor, setEditor] = useState(null);
  const onInit = (editor) => {
    setEditor(editor);
    window.editor = editor;
    editor.BlockManager.add('my-first-block', {
      label: 'Simple block',
      content: '<div class="my-block"><p><span>The OVIS Project is a nonprofit organization whose mission is to connect our local communities by designing simple projects that positively affect people.  We try to answer the challenges communities face by utilizing those that make up our communities.  </span></p><p><span></span></p><p><span>Our mission is to offer a platform</span><span>where</span><span> local movers and shakers can create projects that solve small societal challenges before they become too big to resolve.  These projects provide a bridge between individual challenges and solutions designed with passion and implemented with success in mind.</span></p></div>',
    });    
  };

  const uploadFileToS3 = (presignedPostData, file) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();

      console.log(presignedPostData);
      Object.keys(presignedPostData.fields).forEach(key => {
        formData.append(key, presignedPostData.fields[key]);
      });
      // Actual file has to be appended last.
      formData.append("file", file);
      axios.post(presignedPostData.url, formData)
      .then(response => {
        console.log(response)
        let result = {
          data: [
            {
              src: response.request.responseURL + file.name,
              type: 'image',
              height: 100,
              width: 200,
            },
          ]
        }
        console.log(result);
        window.editor.AssetManager.add(result.data);
      })
      .catch(error => {
        console.log(JSON.stringify(error));
      })  
    });
  };

  const onUpload = (form_files) => {
    let url = "https://api.ovisproject.org/v1/ovis/app/uploads/s3presigned";
    let result = {};
    for(var i in form_files){      
      let file_data = form_files[i];
      if(isObject(file_data)){


        axios.post(url, {params: 
          { queryParams: {
            bucket_name: "ovisuploads", 
            object_key: "image.jpg"
          }
        }})
        .then(response => {
          result = uploadFileToS3(response.data, file_data);
        })
        .catch(error => {
          console.log(JSON.stringify(error));
        })   
      }
    };
    return result;
  }; 

  const onClick = () => {
    if (!editor) return;
    console.log("click me", editor)
    const pages = editor.Pages.getAll().map((page) => {
      const component = page.getMainComponent();
      return {
        page,
        html: editor.getHtml({ component }),
        css: editor.getCss({ component }),
      };
    });

    console.log("pages", pages);

    const projectData = editor.getProjectData();
    console.log("projectData", projectData);
  };

  return (
    <>
      <GrapesjsReact
        id="grapesjs-react"
        // plugins={[testplugin, chartLibComponent, gjsForms, gjsBlock, gjsTailwind]}
        plugins={[gjsWebpage, gjsBlock]}
        // pluginsOpts={{chartLibComponent: {}, [gjsForms]: {  },[gjsTailwind]: {  }}}
        onInit={onInit}
        assetManager={{
          credentials: "omit",
          embedAsBase64: false,
          uploadFile: async (e) => { 
              var files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
                onUpload(files);            
            },
          upload: "https://wn5uv63nwd.execute-api.us-east-1.amazonaws.com/v1/ovis/app/uploads/assets?bucket_name=ovisuploads&object_key=files", // Example endpoint
          customFetch: async (url, options) => {
            console.log("url", url)
            console.log("options", options)
          },
        }}
      />
      <button onClick={onClick}>Console log HTML/CSS</button>
    </>
  );
}

export default Newsletter;


