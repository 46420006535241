import React, { useState, useEffect } from 'react';
import {
    Avatar,
    Breadcrumbs as MuiBreadcrumbs,
    Box,
    Button,
    Card as MuiCard,
    CardActions,
    CardContent as MuiCardContent,
    CardMedia as MuiCardMedia,
    Chip as MuiChip,
    Divider as MuiDivider,
    Grid,
    Link,
    TextField,
    Typography as MuiTypography,
  } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import FilledInput from '@mui/material/FilledInput';
import { ImageProps } from '../../types/page'
import { lighten } from 'polished';


interface FormValues {
    specs?: string;
    laptop?: string;
    headset?: string;
    review?: number;
}

const BannerPanel: React.FC<ImageProps> = ({props}) => {
    const [formValues, setFormValues] = useState<FormValues>({});

    const [name, setName] = useState('');
    const [isBool, setIsBool] = useState(false)

    const handleTextFieldChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => {
        const { name, value } = event.target;
        setFormValues({
          ...formValues,
          [name]: value,
        });
      };

      const handleCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        checked?: boolean
      ) => {
        const { name } = event.target;
        if(!checked) {
            //@ts-ignore still working on the typing
            delete formValues[name]
        } else {
            setFormValues({
                ...formValues,
                [name]: checked,
              });
        }
      };

      const handleRatingChange = (
        value: number | null,
        name: string
      ) => {
        setFormValues({
          ...formValues,
          [name]: value,
        });
      };

      const handleSubmit = () => {
        fetch('/somewhere.html', {
          method: 'POST',
          body: JSON.stringify(formValues),
          headers: {
            'Content-Type': 'application/json'
          },
        })
      }




    
        const onChangeHandler = (e:any) => {
            console.log(e.target);
            console.log(encodeURIComponent(e.target.id));
            setName(e.target.value)
            // if (!name.match(/^[A-Za-z\s]*$/)) {
            //     setIsBool(true);
            //     console.log('error: allows letters only')
            // } else {
            //     setIsBool(false);

            // }
        }


    return (
        <div key={"m" + props?.id}>
            {/* <Recorder key={image.id} name={image.name}></Recorder> */}
            {/* <img key={image.key} src={image.url}/> */}
            <div style={{
            backgroundImage: `url(${props?.url})`,
            backgroundRepeat: 'no-repeat',
            width: '493px',
            height: '370px',

            }}>
            <Box
                sx={{
                width: 150,
                height: 150,
                marginLeft: 2,
                paddingTop: 5,
                // '&:hover': {
                //   backgroundColor: 'primary.main',
                //   opacity: [0.9, 0.8, 0.7],
                // },
                }}>
                <div>
                <Grid container direction={"column"} spacing={5}>
                    <Grid item>
                    {/* <TextField label="Enter Donation" variant="outlined" /> */}
                    <FormControl fullWidth 
                        sx={{ m: 1,
                            backgroundColor: (theme) => theme.palette.background.default
                        }} 
                        variant="filled"
                        >
                        <InputLabel htmlFor="filled-adornment-amount">Enter Value</InputLabel>
                        <FilledInput
                        id="filled-adornment-amount"
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}

                        />
                        
                    </FormControl>
                    <FormControl fullWidth 
                        sx={{ m: 1,
                            backgroundColor: (theme) => theme.palette.background.default
                        }} 
                        variant="filled">
                        <InputLabel htmlFor="filled-adornment-amount">Enter Tags</InputLabel>
                        <FilledInput
                        id={"name_" + props?.key}
                        startAdornment={<InputAdornment position="start"></InputAdornment>}
                        // onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        //     setName(event.target.value);
                        //   }}               
                        onBlur={onChangeHandler}         
                        />
                    </FormControl>                    
                    </Grid>
                </Grid>

                {/* <TextField label="Email Address" id="outlined-size-normal" defaultValue="Normal" />
                <TextField label="Amount" id="outlined-size-normal" defaultValue="Normal" /> */}
                </div>
            </Box>
            </div>
        </div>

);
}

export default BannerPanel;
