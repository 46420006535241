import * as React from "react";
import { useNavigate, Navigate, useLocation } from "react-router-dom";

import dashboardItems from "../../components/sidebar/dashboardItems";
import useAuth from "../../hooks/useAuth";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


interface AuthGuardType {
  children: React.ReactNode;
}

// https://stackoverflow.com/questions/57676477/how-to-find-the-key-of-a-value-in-a-nested-object-recursively
const findKeyByValue = (obj:any, equalsExpression:any):any => {
  // Loop key->value pairs of the input object.
  for (var [key, v] of Object.entries(obj)) {
    // if the value is an array..
    if (Array.isArray(v)) {
      // Loop the array.
      for (let i = 0; i < v.length; i++) {
        // check whether the recursive call returns a result for the nested element.
        let res = findKeyByValue(v[i], equalsExpression);
        // if so, the key was returned. Simply return.
        if (res !== null && res !== undefined){
          return res;
        }
      }
    }
    // otherwise..
    else {
      // if the value is not null and not undefined.
      if (v !== null && v !== undefined) {
        // if the value is an object (typeof(null) would give object, hence the above if). 
        if (typeof(v) === 'object') {
          // check whether the value searched is an object and the match is met.
          if (equalsExpression(v)) return key;
          // if not, recursively keep searching in the object.
          let res = findKeyByValue(v, equalsExpression);
          // if the key is found, return it.
          if (res !== null && res !== undefined){
            return res;
          }
        }
        else {
          // finally, value must be a primitive or something similar. Compare.
          let res = equalsExpression(v);
          // if the condition is met, return the key.
          if (res) return key;
          // else.. continue.
        }
      }
      else continue;
    }
  }
}

const GetPageGroup = ():string => {
  const router = useLocation();
  const currentRoute = router.pathname;
  for (var index in dashboardItems) {
    for (var page in dashboardItems[index].pages) {
      var page_attr = dashboardItems[index].pages;
      var page_obj = page_attr[page];
      if(page_obj.hasOwnProperty('href')){
        if (currentRoute === page_obj.href){
          console.log("LINK FOUND")
        }
      }
      if(page_obj.hasOwnProperty('children')){
        for (var child in page_obj.children) {
          var child_page_obj = page_obj.children[child];
          if(child_page_obj.hasOwnProperty('href')){
            // console.log("1", child_page_obj.href);
            if (currentRoute === child_page_obj.href){
              if(child_page_obj.hasOwnProperty('group')){
                // console.log("FOUND ME A LINK2", child_page_obj.group)
                if(child_page_obj.group){
                  return child_page_obj.group;
                }
              }
            }
          }
        }
      }
    }    
  }
  return "";
}

// For routes that can only be accessed by authenticated users
function GroupGuard({ children }: AuthGuardType) {
  const [open, setOpen] = React.useState(true);
  const { isAuthenticated, isInitialized, getGroups } = useAuth();
  const navigate = useNavigate();
  // var val = findKeyByValue(dashboardItems, (found:any) => found === '/pages/mailer');
  // var page = dashboardItems[0].find(x => x.href === 'pages');

  const handleClose = () => {
    setOpen(false);
  };

  const handleDifferntUserClose = () => {    
    navigate("/auth/sign-in")
  };


  var page_group = GetPageGroup();
  var cognito_groups = getGroups();
  
  if (isInitialized && !isAuthenticated) {
    return <Navigate to="/auth/sign-in" />;
  }
  if (!cognito_groups.includes(page_group)) {
    // return <Navigate to="/auth/sign-in" />;
    return <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"ACCESS DENIED"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You currently do not have access to this page.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDifferntUserClose}>Login as a different user</Button>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  }
  return <React.Fragment>{children}</React.Fragment>;
}

export default GroupGuard;
