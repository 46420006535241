
import React, { useState, useEffect } from 'react';
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import {
    Box,
    Container,    
    Grid,
    Typography as MuiTypography,
  } from "@mui/material";
import { Markup } from 'interweave';
import { Project, ProjectDetails, ProjectProps } from '../../../types/project'
import { ComponentProps } from '../../../types/page'
import Divider from '@mui/material/Divider';


const Typography = styled(MuiTypography)(spacing);
const Wrapper = styled.div`
  padding-top: 3.5rem;
  position: relative;
  text-align: center;
  overflow: hidden;
`;
const Content = styled.div`
  padding: ${(props) => props.theme.spacing(6)} 0;
  line-height: 150%;
`;

type HTMLProps = {
    html?: string;    
  };

const Feature: React.FC<HTMLProps> = ({ html }) => {
    return (
      <Grid item xs={12} lg={12}>
        <Box>
          <Typography variant="subtitle1" align="left" gutterBottom my={4}>
            <Markup content={html}/>
          </Typography>
        </Box>
      </Grid>
    );
  };

  function getAllIndexes(arr:string[]=[], val:string) {
    let indexes = [], i = -1;
    while ((i = arr.indexOf(val, i+1)) != -1){
        indexes.push(i);
    }
    return indexes;
  }

  const Fullpage: React.FC<ComponentProps> = ({props}) => {
    return (
      <Wrapper>
        <Container>
          <Grid container alignItems="center" justifyContent="center" spacing={4}>
            {/* <Grid item xs={12} lg={12}>
                <Box ml={6}>
                <Typography variant="h1" gutterBottom>
                    {pageTitle}
                </Typography>
                </Box>
            </Grid> */}
            <Grid item xs={12} sm={9} md={8} lg={8}>
              <Content>
                <Grid container spacing={6}>
                  { 

                      <Feature
                          html={props?.content?.body}
                      />

                  }
                </Grid>
              </Content>
            </Grid>
          </Grid>
        </Container>
      </Wrapper>
    );
  }

export default Fullpage;