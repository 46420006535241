import React from "react";

import async from "./components/Async";


// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import PresentationLayout from "./layouts/Presentation";

// Guards
import AuthGuard from "./components/guards/AuthGuard";
import GroupGuard from "./components/guards/GroupGuard";

// Auth components
// Auth components
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import Verify from "./pages/auth/Verify";
import ResetPassword from "./pages/auth/ResetPassword";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";

// Form components
import SelectionCtrls from "./pages/forms/SelectionControls";
import Selects from "./pages/forms/Selects";
import TextFields from "./pages/forms/TextFields";

// Landing
import Landing from "./pages/presentation/Landing";
import PublicProjects from "./pages/presentation/ProjectSearch";
import Privacy from "./pages/presentation/Privacy";
import Terms from "./pages/presentation/Terms";
import Contribution from "./pages/presentation/Contribution";
import ContactUs from "./pages/presentation/ContactUs";
import Donate from "./pages/presentation/Donate";
import History from "./pages/presentation/History";

// Protected routes
import ProtectedPage from "./pages/protected/ProtectedPage";

import Orders from "./pages/pages/Orders";
// Page components
import Projects from "./pages/pages/Projects";
import Inventory from "./pages/pages/Inventory";
// Page components
import Blank from "./pages/pages/Blank";
import InvoiceDetails from "./pages/pages/InvoiceDetails";
import InvoiceList from "./pages/pages/InvoiceList";
import Pricing from "./pages/pages/Pricing";
import Mailer from "./pages/pages/Mailer";
import Settings from "./pages/pages/Settings";
import Chat from "./pages/pages/Chat";


// Dashboard components
const Default = async(() => import("./pages/dashboards/Default"));

// Form components
const Pickers = async(() => import("./pages/forms/Pickers"));
const Editors = async(() => import("./pages/forms/Editors"));
const Formik = async(() => import("./pages/forms/Formik"));
const AccomplishmentsUserPage = async(() => import("./pages/forms/AccomplishmentsUserPage"));

// Icon components
const Profile = async(() => import("./pages/pages/Profile"));
const Tasks = async(() => import("./pages/pages/Tasks"));




const routes = [
    {
      path: "/",
      element: <PresentationLayout />,
      children: [
        {
          path: "",
          element: <Landing />,
        },
      ],
    },
    {
      path: "/project_search",
      element: <PresentationLayout />,
      children: [
        {
          path: "",
          element: <PublicProjects />,
        },
      ],
    },
    {
      path: "/privacy",
      element: <PresentationLayout />,
      children: [
        {
          path: "",
          element: <Privacy />,
        },
      ],
    },
    {
      path: "/terms",
      element: <PresentationLayout />,
      children: [
        {
          path: "",
          element: <Terms />,
        },
      ],
    },
    {
      path: "/contribution",
      element: <PresentationLayout />,
      children: [
        {
          path: "",
          element: <Contribution />,
        },
      ],
    },    
    {
      path: "/contact_us",
      element: <PresentationLayout />,
      children: [
        {
          path: "",
          element: <ContactUs />,
        },
      ],
    }, 
    {
      path: "/history",
      element: <PresentationLayout />,
      children: [
        {
          path: "",
          element: <History />,
        },
      ],
    },         
    {
      path: "/donate",
      element: <PresentationLayout />,
      children: [
        {
          path: "",
          element: <Donate />,
        },
      ],
    },    
    {
      path: "dashboard",
      element: <DashboardLayout />,
      children: [
        {
          path: "default",
          element: (<GroupGuard><AuthGuard><Default /></AuthGuard></GroupGuard>),
        },        
        {
          path: "projects",
          element: <Projects />,
        },        
        {
          path: "inventory",
          element: <Inventory />,
        },       
      ],
    },
    {
      path: "orders",
      element: <DashboardLayout />,
      children: [
        {
          path: "",
          element: <Orders />,
        },
      ],
    },     
    {
      path: "tasks",
      element: <DashboardLayout />,
      children: [
        {
          path: "",
          element: <Tasks />,
        },
      ],
    },    
    {
      path: "pages",
      element: <DashboardLayout />,
      children: [
        {
          path: "profile",
          element: (<GroupGuard><Profile /></GroupGuard>),
        },
        {
          path: "settings",
          element: (<GroupGuard><Settings /></GroupGuard>),
        },
        {
          path: "pricing",
          element: (<GroupGuard><Pricing /></GroupGuard>),
        },
        {
          path: "mailer",
          element: (<GroupGuard><Mailer /></GroupGuard>),
        },        
        {
          path: "chat",
          element: (<GroupGuard><Chat /></GroupGuard>),
        },
        {
          path: "blank",
          element: (<GroupGuard><Blank /></GroupGuard>),
        },
      ],
    },    
    {
      path: "auth",
      element: <AuthLayout />,
      children: [
        {
          path: "sign-in",
          element: <SignIn />,
        },
        {
          path: "sign-up",
          element: <SignUp />,
        },
        {
          path: "verify",
          element: <Verify />,
        },
        {
          path: "reset-password",
          element: <ResetPassword />,
        },
        {
          path: "404",
          element: <Page404 />,
        },
        {
          path: "500",
          element: <Page500 />,
        },
      ],
    },
    {
      path: "forms",
      element: <DashboardLayout />,
      children: [
        {
          path: "pickers",
          element: <Pickers />,
        },
        {
          path: "selection-controls",
          element: <SelectionCtrls />,
        },
        {
          path: "selects",
          element: <Selects />,
        },
        {
          path: "text-fields",
          element: <TextFields />,
        },
        {
          path: "editors",
          element: <Editors />,
        },
        {
          path: "formik",
          element: <Formik />,
        },
        {
          path: "accomplishments-user-page",
          element: <AccomplishmentsUserPage />,
        },        
      ],
    },
    {
      path: "private",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: <ProtectedPage />,
        },
      ],
    },
    {
      path: "*",
      element: <AuthLayout />,
      children: [
        {
          path: "*",
          element: <Page404 />,
        },
      ],
    },
  ];

export default routes;