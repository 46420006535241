import React from "react";
import styled, { withTheme, ThemeProps } from "styled-components/macro";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import faker from 'faker';
import { MoreVertical } from "react-feather";

import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  IconButton,
  Theme,
} from "@mui/material";
import { spacing } from "@mui/system";
import { alpha } from "@mui/material/styles";


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
  height: 378px;
`;


export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Chart.js Line Chart',
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];


export const data = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    {
      label: 'Dataset 2',
      data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
};

function LineChart({ theme }: ThemeProps<Theme>) {

  // const data = (canvas: HTMLCanvasElement) => {}

  // const data = (canvas: any) => {
  //   const ctx = canvas.getContext("2d");

  //   const gradient = ctx.createLinearGradient(0, 0, 0, 300);
  //   gradient.addColorStop(0, alpha(theme.palette.secondary.main, 0.0875));
  //   gradient.addColorStop(1, "rgba(0, 0, 0, 0)");

  //   return {
  //     labels: [
  //       "Jan",
  //       "Feb",
  //       "Mar",
  //       "Apr",
  //       "May",
  //       "Jun",
  //       "Jul",
  //       "Aug",
  //       "Sep",
  //       "Oct",
  //       "Nov",
  //       "Dec",
  //     ],
  //     datasets: [
  //       {
  //         label: "Sales ($)",
  //         fill: true,
  //         backgroundColor: gradient,
  //         borderColor: theme.palette.secondary.main,
  //         tension: 0.4,
  //         data: [
  //           2115, 1562, 1584, 1892, 1587, 1923, 2566, 2448, 2805, 3438, 2917,
  //           3327,
  //         ],
  //       },
  //       {
  //         label: "Orders",
  //         fill: true,
  //         backgroundColor: "transparent",
  //         borderColor: theme.palette.grey[500],
  //         borderDash: [4, 4],
  //         tension: 0.4,
  //         data: [
  //           958, 724, 629, 883, 915, 1214, 1476, 1212, 1554, 2128, 1466, 1827,
  //         ],
  //       },
  //     ],
  //   };
  // };

  // const options = {
  //   maintainAspectRatio: false,
  //   plugins: {
  //     legend: {
  //       display: false,
  //     },
  //   },
  //   scales: {
  //     x: {
  //       grid: {
  //         color: "rgba(0,0,0,0.0)",
  //       },
  //     },
  //     y: {
  //       grid: {
  //         color: "rgba(0,0,0,0.0375)",
  //         fontColor: "#fff",
  //       },
  //     },
  //   },
  // };

  return (
    <Card mb={6}>
      <CardHeader
        action={
          <IconButton aria-label="settings" size="large">
            <MoreVertical />
          </IconButton>
        }
        title="Total revenue"
      />
      <CardContent>
        <ChartWrapper>
          {/* <Line type='line' data={data} options={options} /> */}
          <Line options={options} data={data} />
        </ChartWrapper>
      </CardContent>
    </Card>
  );
}
export default withTheme(LineChart);
