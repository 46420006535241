import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";

import {
  Button,
  CardActions,
  CardContent,
  FormControlLabel,
  FormGroup,
  Box,
  Grid,
  Link,
  Switch,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardHeader as MuiCardHeader,
  Divider as MuiDivider,
  Typography,
  TextField
} from "@mui/material";


import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Stack from '@mui/material/Stack';

import { StarBorder as StarIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";

import { ComponentProps } from '../../../types/page'
// import { Box } from "react-feather";

const Card = styled(MuiCard)(spacing);

const CardHeader = styled(MuiCardHeader)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Price = styled.div`
  text-align: center;
  padding-bottom: ${(props) => props.theme.spacing(3)};
`;

const Header = styled.div`
  padding: ${(props) => props.theme.spacing(6)} 0;
`;



var amount = "15";

const currency = "USD";
const style = { "layout": "vertical" };

type ResponseProps = {
  response: string;

};

const ResponseCard: React.FC<ResponseProps> = (response) => {
  console.log("response", response);
  return (
    <Card
      style={{
        backgroundColor: 'white',
        opacity: 0.7
      }} p={4} m={5}>
      <CardHeader
        title=""
        titleTypographyProps={{ align: "center" }}
        pb={5}
        pt={5}
      />
      <CardActions>
        <Typography
          component="h2"
          variant="h3"
          color="textPrimary"
          align="center"
        >
          Thank you for your donation. Your transaction has been completed, and a receipt for tax purposes has been emailed to you. You may log into your account at www.paypal.com/us to view details of this transaction.
        </Typography>
      </CardActions>
    </Card>
  );
}


// Custom component to wrap the PayPalButtons and handle currency changes
const ButtonWrapper = ({ invisible, currency, showSpinner, plan_id }: any) => {
  // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
  // This is the main reason to wrap the PayPalButtons in a new component
  var [{ options, isPending }, dispatch] = usePayPalScriptReducer();
  const [response, setResponse] = useState(false);
  const [orderID, setOrderID] = useState("");
  var option_onetime = {
    ...options,
    currency: "USD",
    // intent: "subscription",
  }
  var option_subscribe = {
    ...options,
    intent: "subscription",
    vault: true,
  }

  var toggle_options = (invisible == false) ? option_onetime : option_subscribe;

  if (!invisible) {
    options.intent = "";
    options.currency = currency;
  }

  useEffect(() => {
    dispatch({
      type: "resetOptions",
      value: toggle_options,
    });
  }, [invisible, currency, showSpinner, plan_id]);

  function onError(data: any, actions: any) {
    console.log(data, actions);
  }

  function displayResponse(orderId: string) {
    console.log(orderId);
    setOrderID(orderId)
  }

  return (<>
    {(showSpinner && isPending) && <div className="spinner" />}
    {
      invisible == false ? (
        !response ? (
        <PayPalButtons
          style={{
            layout: "vertical",
            label: "donate"
          }}
          disabled={false}
          forceReRender={[amount, currency, style]}
          fundingSource="paypal"
          createOrder={(data, actions) => {
            return actions.order
              .create({
                purchase_units: [
                  {
                    amount: {
                      currency_code: currency,
                      value: amount,
                      breakdown: {
                        item_total: {
                          currency_code: "USD",
                          value: amount,
                        }
                      }
                    },
                    items: [{
                      name: "donation-example",
                      quantity: "1",
                      unit_amount: {
                        currency_code: "USD",
                        value: amount,
                      },
                      category: "DONATION",
                    }],
                  },
                ],
              })
              .then((orderId) => {
                // Your code here after create the order
                return orderId;
              });
          }}
          onApprove={function (data: any, actions: any) {

            return actions.order?.capture().then(function () {
              // Your code here after capture the order
              setResponse(true);
              console.log("Action taken", actions);

            });
          }}          
        />
        ) : (
          <ResponseCard response={orderID}></ResponseCard>
        )
      ) : (
        !response ? (
          <PayPalButtons
            style={{
              label: "subscribe",
            }}
            createSubscription={(data, actions) => {
              return actions.subscription
                .create({
                  'plan_id': plan_id
                })
                .then((orderId) => {
                  // Your code here after create the order
                  // console.log(orderId);
                  return orderId;
                });
            }}
            onError={(err) => {
              console.error('error from the onError callback', err);
            }}
            onApprove={function (data: any, actions: any) {
              if(data.orderID){
                setResponse(true);
              }
              return actions.order?.capture().then(function () {
                // Your code here after capture the order
                setResponse(true);

                console.log("Action taken", actions);

              });
            }}
          />                
        ) : (
          <ResponseCard response={orderID}></ResponseCard>
        )


      )}
  </>
  );
}


const Pricing: React.FC<ComponentProps> = ({ props }) => {
  const [invisible, setInvisible] = useState(false);
  const [description, setDescription] = useState("15");
  const [isActive, setActive] = useState(false);
  // const [amount, setAmount] = useState("15");
  const [alignment, setAlignment] = React.useState('left');

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    setAlignment(newAlignment);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    amount = newValue;
    setDescription(newValue);
  }


  const tier1 = [
    <ToggleButton key="15" value="15" aria-label="15 dollars" onClick={() => { changeMyVariable("15", "P-0W387381W20541454MPNSUYY"); }}>
      <Typography
        component="h2"
        variant="h3"
        color="textPrimary"
        align="center"
        display="inline"
      >
        $15
      </Typography>
    </ToggleButton>,
    <ToggleButton key="25" value="25" aria-label="25 dollars" onClick={() => { changeMyVariable("25", "P-5GK7898984292802XMPNMHDA"); }}>
      <Typography
        component="h2"
        variant="h3"
        color="textPrimary"
        align="center"
        display="inline"
      >
        $25
      </Typography>
    </ToggleButton>,
    <ToggleButton key="50" value="50" aria-label="50 dollars" onClick={() => { changeMyVariable("50", "P-77X22782BM431381SMPNTHEA"); }}>
      <Typography
        component="h2"
        variant="h3"
        color="textPrimary"
        align="center"
        display="inline"
      >
        $50
      </Typography>
    </ToggleButton>,
    <ToggleButton key="100" value="100" aria-label="100 dollars" onClick={() => { changeMyVariable("100", "P-35760415A5618910VMPNTVWA"); }}>
      <Typography
        component="h2"
        variant="h3"
        color="textPrimary"
        align="center"
        display="inline"
      >
        $100
      </Typography>
    </ToggleButton>,
  ];

  const tier2 = [
    <ToggleButton key="250" value="250" aria-label="250 dollars" onClick={() => { changeMyVariable("250", "P-4LR780101C4446359MPNTWRQ"); }}>
      <Typography
        component="h2"
        variant="h3"
        color="textPrimary"
        align="center"
        display="inline"
      >
        $250
      </Typography>
    </ToggleButton>,
    <ToggleButton key="500" value="500" aria-label="500 dollars" onClick={() => { changeMyVariable("500", "P-6AP60386T4572335AMPNTW2I"); }}>
      <Typography
        component="h2"
        variant="h3"
        color="textPrimary"
        align="center"
        display="inline"
      >
        $500
      </Typography>
    </ToggleButton>,
  ];

  const control = {
    value: alignment,
    onChange: handleChange,
    exclusive: true,
  };

  const handleBadgeVisibility = () => {
    console.log(invisible);
    setInvisible(!invisible);
    console.log(invisible);
  };


  // get the state for the sdk script and the dispatch method
  const [{ options }, dispatch] = usePayPalScriptReducer();
  const [plan_id, setPlan_id] = useState("P-0W387381W20541454MPNSUYY");
  const [currency, setCurrency] = useState(options.currency);
  const [value, setValue] = useState("");

  function onCurrencyChange({ target: { value } }: any) {
    console.log(value)
    setCurrency(value);
    dispatch({
      type: "resetOptions",
      value: {
        ...options,
        currency: value,
      },
    });
  }

  const toggleClass = () => {
    setActive(!isActive);
  };
  // const initialOptions = {
  //     "client-id": "AdfRt0vdtx3yPV9P5VUtNk7zDUkTmVwSnl-lTiUPAfkxxQ6WxvJ942p0F5AFF03Ndg-EM9vLWljPj-8R",
  //     currency: "USD",
  //     intent: "capture",
  //     "data-client-token": "abc123xyz==",
  // };

  const changeMyVariable = (a: any, plan_id: string) => {
    console.log(a, plan_id);
    setPlan_id(plan_id);
    setDescription(a);
    amount = a;
    setValue("");
  };

  // const textFieldRef = useRef<any>();
  // const handleTextInputChange = () => {
  //   amount = textFieldRef.current?.value;
  //   setDescription(textFieldRef.current?.value);

  // };



  return (
    <React.Fragment>
      <Grid container justifyContent="center">
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={invisible}
                onChange={handleBadgeVisibility}
              />
            }
            label="Monthly Contribution"
          />
        </FormGroup>
      </Grid>

      <Stack spacing={2} alignItems="center">
        <ToggleButtonGroup {...control} aria-label="Small sizes">
          {tier1}
        </ToggleButtonGroup>
        <ToggleButtonGroup  {...control} aria-label="Medium sizes">
          {tier2}
          {
            invisible == false ? (
              <Typography
                component="h2"
                variant="h3"
                color="textPrimary"
                align="center"
                display="inline"
              >
                <TextField id="outlined-basic" label="Other" variant="outlined"
                  onChange={onChange}
                  // defaultValue={value}
                  // value={value}
                  InputProps={{ inputProps: { style: { textAlign: "right" }, } }} />
                {/* { invisible ? ( <span></span> ) : ( <span>/m</span> ) } */}
              </Typography>

            ) : (
              <span></span>
            )
          }
        </ToggleButtonGroup>
      </Stack>
      {/* <Grid container justifyContent="center">
      <Grid item xs={12} lg={12}>
        <Grid container spacing={12} alignItems="flex-end">
          <Grid item xs={6}> */}

      <Stack
        display="flex"
        alignItems="center"
        mb={10}
      >
        <Card
          style={{
            backgroundColor: 'white',
            opacity: 0.7
          }} p={4} m={5}>
          <CardHeader
            title=""
            titleTypographyProps={{ align: "center" }}
            pb={5}
            pt={5}
          />
          <CardActions>
            <Typography
              component="h2"
              variant="h3"
              color="textPrimary"
              align="center"
            >
              Your donation will be ${description}.00 {invisible ? (<span> each month.</span>) : (<span></span>)}
            </Typography>
          </CardActions>
        </Card>
        <div>
          <ButtonWrapper
            invisible={invisible}
            currency={currency}
            showSpinner={false}
            plan_id={plan_id}
          />
        </div>
        <div></div>
      </Stack>

      <Header>
        <Typography variant="h3" gutterBottom align="center">
          The Ovis Project is a 501c3 organization that helps those in need.
        </Typography>
        <Typography variant="subtitle1" gutterBottom align="center">
          We design projects that can be scaled out so others can take what we have built and add on.
        </Typography>
        <Typography variant="subtitle1" gutterBottom align="center">
          Your contribution is truly appreciated and allows us to make our world a better place.
        </Typography>
        <Grid container justifyContent="center">
          <Box alignItems="left">
            <Typography variant="subtitle1" gutterBottom align="left" width={600}>
              <ul>
                <li><b>$15</b> - Let your donation be combined with others for projects that positively affect our communities.</li>
                <li><b>$50</b> - Helps feed the homeless through our partnership with Celebrate Recovery. </li>
                <li><b>$500</b> - Design your own project alongside the Ovis Project that changes lives.</li>
              </ul>
            </Typography>
          </Box>
        </Grid>
      </Header>


      <Grid container justifyContent="center">
        <Box alignItems="center">
          <Typography variant="subtitle1" gutterBottom align="left" width={600}>
            You may also make checks payable to:<br /><br /><b>Ovis Project</b><br /><b>2630 Kanawha Blvd<br />Charleston WV 25311</b>
          </Typography>
        </Box>
      </Grid>


    </React.Fragment>
  );
}

export default Pricing;
