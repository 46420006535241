import React, { useState, useEffect } from 'react';
import apiService from "../../../app/services/api";
import { 
  renderSwitch
  } from "../components/Switcher"
import AppBar from "../components/AppBar";
import Footer from "../components/Footer";
import { Page } from '../../../types/page'
import { Helmet } from "react-helmet-async";
function Presentation() {
  const [pageData, setPageData] = useState<Page>()
  useEffect(() => {
    apiService.getPages().then((results) => {
        var pages = results["Items"];
        for (let x in pages) {
          if (pages[x].pageName === "donate") {            
            setPageData(pages[x]);
          }
        }
      });
  }, [])

  return (
    <React.Fragment>
      <AppBar />
      <Helmet title="Donate" />
      {
      pageData && 
        pageData.components?.map((c:any, i: number) => {
          return renderSwitch(c.type, c)
        })
      }
      <Footer />
    </React.Fragment>
  );
}

export default Presentation;
