import axios from "axios";
// import axios from "../utils/axios";
// import useAuth from "../../hooks/useAuth";

// const { authHeader } = useAuth();
// const API_URL = "http://localhost:8080/api/auth/";

const axiosInstance = axios.create({
  baseURL: "https://api.ovisproject.org/v1",
  headers: {
    "Content-type": "application/json",
  },
});

axiosInstance.interceptors.request.use((config: any) => {
  // console.log("CONFIG", config);
  // const authHeader = localStorage.getItem("accessToken");

  // config.headers["Authorization"] = authHeader;
  const authHeader = localStorage.getItem("accessToken");
  config.headers["Authorization"] = "Bearer "+ authHeader;
  return config;
});

export default axiosInstance;