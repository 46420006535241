import axios from "axios";

const axiosInstancePublic = axios.create({
      baseURL: "https://api.ovisproject.org/v1",
      headers: {
        "Content-type": "application/json"
      },
    }
  );

  axiosInstancePublic.interceptors.request.use((config: any) => 
{
  return config;
});


axiosInstancePublic.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

export default axiosInstancePublic;
